import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { FlipResponse } from 'types';

type Props = {
  response: FlipResponse;
  onRequestClose: OnRequestCloseType;
};

const RemoveFromTopicModal = ({ response, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.type === 'done' && fetcher.data?.ok) onRequestClose(t('removeFromTopicModal.successfullyRemoved'));
  }, [fetcher, onRequestClose, t]);

  const onSubmit = () => {
    const formData = new FormData();
    formData.append('_action', 'removeResponseFromGroup');
    formData.append('groupId', response.grid_id?.toString() || '');
    formData.append('id', response.id.toString());
    fetcher.submit(formData, { method: 'post', action: resourceRoutes.remove });
  };

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('removeFromTopicModal.removeFromTopic')}</h1>
      <p className="fk-modalBody mb0">
        {response.topic_active ? (
          <Trans i18nKey="removeFromTopicModal.removeFromGridConfirm">
            Your video will be removed from <b>{{ topicName: response.topic_title }}</b>, but will stay saved in your
            videos. Any related comments or likes will be permanently deleted for everyone.
          </Trans>
        ) : (
          t('removeFromTopicModal.videoWillBeRemoved')
        )}
      </p>
      <Modal.Actions className="mt2">
        <Button
          onClick={() => {
            onRequestClose();
          }}
          theme="secondary"
          data-testid="removeFromTopicModal__button__cancel"
        >
          {t('common.cancel')}
        </Button>
        <ButtonSubmit
          type="button"
          data-testid="removeFromTopicModal__button__delete"
          loading={fetcher.state !== 'idle'}
          onClick={onSubmit}
        >
          {t('common.remove')}
        </ButtonSubmit>
      </Modal.Actions>
    </Modal>
  );
};

export default RemoveFromTopicModal;
