import { Anchor, Modal } from '@flipgrid/flipkit';
import { useSearchParams } from '@remix-run/react';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatName } from '../../../helper/formatting';
import ShareQRCodeBtn, { links as shareQRCodeBtnStyles } from '~/components/Share/ShareQRCodeBtn';
import SocialContent, { links as socialContentStyles } from '~/components/Share/SocialContent';
import externalLinks from '~/constants/externalLinks';
import GlobalContext from '~/contexts/globalContext';
import { handleError } from '~/helper/imgOnError';
import shareModalStyles from '~/styles/components/Modals/ShareModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { SyntheticEvent } from 'react';
import type { GroupAccessControls, ShareEntity } from 'types';

export const links = () => [
...shareQRCodeBtnStyles(),
...socialContentStyles(),
{ rel: 'stylesheet', href: shareModalStyles }];


export type Props = {
  accessControl?: GroupAccessControls;
  entity: ShareEntity;
  onRequestClose: OnRequestCloseType;
  shareBaseUrl: string;
  token: string;
  name?: string;
  image_url?: string;
};

const ShareModal = ({ accessControl, entity, onRequestClose, token, name, image_url, shareBaseUrl }: Props) => {
  const { item } = entity;
  const { t } = useTranslation();
  const [shareToken, setShareToken] = useState('');
  const { openPopover, setQueuedTourNewTopic } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const isNewTopic = entity.type === 'Topic' && searchParams.get('new');

  const typeProps = useMemo(() => {
    switch (entity.type) {
      case 'Comment':
        return {
          displayName: entity.item.display_name,
          helpText: t('shared.onlyShareThisLink'),
          image: entity.item.image_url,
          shareDisplayName: t('shareModal.shareVideo', { name: formatName(entity.item) })
        };
      case 'Group':
        return {
          googleEducatorHelpPrompt: t('shared.googleEducatorHelpPrompt'),
          googleEducatorHelpText: t('shared.googleEducatorHelpText'),
          helpText:
          entity.item.access_control === 'student' ?
          t('shareModal.shareGroupWithLink') :
          t('shared.inviteAnyoneToJoinInstantly'),
          image: entity.item.image_url,
          name: entity.item.name,
          shareDisplayName: t('shared.shareGroup')
        };
      case 'MyVideos':
        return {
          download: {
            displayName: entity.item.display_name,
            url: entity.item.video_url || ''
          },
          helpText: t('shareModal.shareYourVideoWithFriendsAndFamily'),
          image: entity.item.image_url,
          name: entity.item.title ? entity.item.title : '',
          shareDisplayName: t('shareModal.shareVideo')
        };
      case 'Response':
        return {
          helpText: t('shareModal.inviteToViewVideo'),
          image: entity.item.image_url,
          name: entity.item.title ? entity.item.title : formatName(entity.item),
          shareDisplayName: t('shared.shareVideo')
        };
      case 'Topic':
        return {
          googleEducatorHelpPrompt: t('shared.googleEducatorHelpPrompt'),
          googleEducatorHelpText: t('shared.googleEducatorHelpText'),
          groupImage: entity.item.image_url,
          helpText: t('shareModal.sendLinkToInvite'),
          image: entity?.item?.focus?.image_url || image_url,
          name: entity.item.title,
          shareDisplayName: t('shared.shareTopic')
        };
      case 'Mixtape':
        return {
          helpText: t('shared.mixtapeShareHelpText', { mixtapeName: entity.item.title }),
          name: entity.item.title,
          shareDisplayName: t('shared.shareMixtape')
        };
      case 'MixtapeResponse':
        return {
          helpText: t('shareModal.inviteToViewVideo'),
          image: entity.item.image_url,
          name: entity.item.name,
          shareDisplayName: t('shared.shareVideo')
        };
    }
  }, [entity.type, entity.item, t, image_url]);

  // item will not exist if there were no users added to the group. In that case we just load the new Group page
  if (!item) return null;

  const closeShareModal = () => {
    if (openPopover) return;

    if (isNewTopic) {
      setQueuedTourNewTopic(true);
      searchParams.delete('new');
      setSearchParams(searchParams);
    }
    onRequestClose();
  };

  return (
    <Modal
      className="shareModal"
      onClose={closeShareModal}
      show
      closeButtonTheme="tertiary"
      closeClassName="shareModal__closeButton">

      {entity.type === 'Topic' ?
      <>
          <div className="shareModal__topButtons">
            {entity.type === 'Topic' && name && typeProps.image ?
          <p className="shareModal__groupName">{name}</p> :
          null}
          </div>
          <div className="shareModal__background">
            <h1 className="shareModal__name">{typeProps.name}</h1>
            <img
            alt={t('shared.groupCover')}
            className="shareModal__image"
            src={`${typeProps.image}?size=medium`}
            onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />

          </div>
        </> :
      entity.type === 'Response' ||
      entity.type === 'MyVideos' ||
      entity.type === 'Comment' ||
      entity.type === 'MixtapeResponse' ?
      <div className="shareModal__iconWrapper">
          <img className="shareModal__responseIcon" alt="" src={typeProps.image} />
        </div> :
      null}
      <div className="shareModal__bodyWrapper">
        <h2 className="fk-modalHeader shareModal__header">
          {entity.type === 'Comment' ?
          t('shareModal.shareDisplayName', { displayName: typeProps.displayName }) :
          typeProps.shareDisplayName}
        </h2>
        <ShareQRCodeBtn
          btnClassName="shareModal__qrCodeButton"
          btnTheme="tertiary"
          entity={entity}
          token={token}
          onShareTokenRetrieval={(privateShareToken) => setShareToken(privateShareToken)}
          btnSize="26" />

        {entity.type !== 'Comment' ? <p className="shareModal__bodyText">{typeProps.helpText}</p> : null}

        {(entity.type === 'Topic' || entity.type === 'Group') &&
        <p className="shareModal__bodyText">
            <b>{typeProps.googleEducatorHelpPrompt}</b>
            <Anchor data-testid="shareModal__googleEducatorHelpText" newTab to={externalLinks.EnableFlip}>
              {typeProps.googleEducatorHelpText}
            </Anchor>
          </p>}


        <SocialContent
          accessControlType={accessControl}
          download={typeProps.download}
          entity={
          entity.type === 'Group' || entity.type === 'Topic' || entity.type === 'Mixtape' ?
          entity :
          entity.type === 'MixtapeResponse' ?
          { ...entity, mixtapeToken: token } :
          {
            ...entity,
            token: shareToken,
            isFetchingToken: !shareToken
          }}

          hideQrCodeBtn
          shareBaseUrl={shareBaseUrl}
          shareDisplayName={typeProps.name || undefined}
          vanityToken={token} />

      </div>
    </Modal>);

};

export default ShareModal;