import {
  FullscreenMaximize24Solid,
  FullscreenMinimize24Solid,
  IcFluentClosedCaption24Filled,
  IcFluentImmersiveReader24Regular,
  IcFluentPlay24Filled,
  IcFluentSettings24Filled,
  IcFluentSpeaker224Filled,
  IcFluentSpeakerMute24Filled,
  Pause24Solid,
  Tooltip,
} from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SeekBar from './Seekbar';
import SettingsMenu from './SettingsMenu';
import { invokeImmersiveReader } from '~/components/ImmersiveReader/ImmersiveReaderHelperFunctions.client';
import resourceRoutes from '~/constants/resourceRoutes';
import { formatName } from '~/helper/formatting';

import type { LegacyRef } from 'react';
import type { Comment, FlipResponse, GridFlipResponse, MixtapeResponse, Topic, VideoFocus } from 'types';

// TODO: Localize file

type Props = {
  autoFocus?: boolean;
  closedCaptions?: boolean;
  currentTime?: number;
  disableFullscreen?: boolean;
  duration?: number;
  entity:
    | {
        item: MixtapeResponse;
        reportId?: never;
        topic?: never;
        type: 'Mixtape';
      }
    | {
        item: GridFlipResponse;
        reportId?: never;
        topic: Topic;
        type: 'Response';
      }
    | {
        item: FlipResponse;
        reportId?: never;
        topic?: never;
        type: 'StandaloneResponse';
      }
    | {
        item: VideoFocus;
        reportId?: never;
        topic: Topic;
        type: 'TopicFocus';
      }
    | {
        item: Comment;
        reportId?: never;
        topic?: never;
        type: 'Comment';
      }
    | {
        item: FlipResponse;
        reportId?: string;
        topic?: never;
        type: 'PrivateShare';
      };
  hasClosedCaptions?: boolean;
  isFullscreen?: boolean;
  isMuted?: boolean;
  isPlaying?: boolean;
  looped?: boolean;
  onReportVideo?: () => void;
  playButtonRef?: LegacyRef<HTMLButtonElement>;
  playbackRate?: number;
  portalTargetID: string;
  seek?: (val: number) => void;
  setPlaybackRate?: (val: number) => void;
  showControls?: boolean;
  toggleCaptions?: () => void;
  toggleFullscreen?: () => void;
  toggleLooped?: () => void;
  toggleMute?: () => void;
  togglePlayback?: () => void;
};

const VideoPlayerControls = ({
  autoFocus = true,
  closedCaptions,
  currentTime = 0,
  disableFullscreen = false,
  duration = 0,
  entity,
  hasClosedCaptions,
  isFullscreen = false,
  isMuted,
  isPlaying,
  looped,
  onReportVideo,
  playbackRate = 1,
  playButtonRef,
  portalTargetID,
  seek,
  setPlaybackRate,
  showControls = true,
  toggleCaptions,
  toggleFullscreen,
  toggleLooped,
  toggleMute,
  togglePlayback,
}: Props) => {
  const { t } = useTranslation();
  const { item, topic, type } = entity;
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [transcriptText, setTranscriptText] = useState('');
  const [irId] = useState(`immersiveReader-${type === 'TopicFocus' ? topic.id : item.id}`);
  const settingsButton = useRef<HTMLButtonElement>(null);
  const fetcher = useFetcher<string>();

  const formatTime = (seconds: number) => {
    // Handle case before metadata is loaded and webm's with no duration
    if (!seconds || seconds === Infinity) return '0:00';

    const mins = Math.floor(seconds / 60);
    let secs: string | number = Math.floor(seconds) % 60;
    if (secs < 10) secs = `0${secs}`;

    return `${mins}:${secs}`;
  };

  const seekTo = (value: number) => {
    if (seek) seek(value);
  };

  const handleToggleCaptions = () => {
    if (toggleCaptions) toggleCaptions();
  };

  const handleToggleFullscreen = () => {
    if (toggleFullscreen) toggleFullscreen();
  };

  const handleToggleMute = () => {
    if (toggleMute) toggleMute();
  };

  const handleTogglePlayback = () => {
    if (togglePlayback) togglePlayback();
  };

  const handleSetPlaybackRate = (value: number) => {
    if (setPlaybackRate) setPlaybackRate(value);
  };

  const toggleSettingsMenu = () => {
    setShowSettingsMenu(isMenuShowing => {
      if (isMenuShowing && settingsButton.current) settingsButton.current.focus();
      return !isMenuShowing;
    });
  };

  const getName = () => {
    let name;
    if (entity?.type === 'TopicFocus') name = entity.topic.title;
    else if (entity?.type === 'Response' || entity?.type === 'StandaloneResponse' || entity?.type === 'PrivateShare')
      name = t('common.responseBy', { name: formatName(entity.item) });
    else if (entity?.type === 'Comment') name = t('common.commentBy', { name: formatName(entity.item) });
    return name;
  };

  useEffect(() => {
    if (fetcher.type === 'done') {
      setTranscriptText(fetcher.data);
    }
  }, [fetcher]);

  useEffect(() => {
    setTranscriptText('');
    if (item.transcript) {
      const qs = new URLSearchParams();

      if (type === 'Comment') {
        qs.append('id', item.id.toString());
        qs.append('group_id', item.grid_id.toString());
        qs.append('_action', 'commentTranscriptTXT');
      } else if (type === 'Response') {
        qs.append('id', item.id?.toString());
        qs.append('group_id', item.grid_id.toString());
        qs.append('_action', 'responseTranscriptTXT');
      } else if (type === 'StandaloneResponse') {
        qs.append('id', item.id?.toString());
        qs.append('_action', 'standaloneResponseTranscriptTXT');
      } else if (type === 'Mixtape') {
        qs.append('id', item.id?.toString());
        qs.append('_action', 'mixtapeTranscriptTXT');
      } else if (type === 'TopicFocus') {
        qs.append('id', topic.id.toString());
        qs.append('group_id', topic.grid_id.toString());
        qs.append('_action', 'topicTranscriptTXT');
      } else if (type === 'PrivateShare') {
        qs.append('id', item.id.toString());
        qs.append('token', item.vanity_token);
        if (entity.reportId) qs.append('reportId', entity.reportId);
        qs.append('_action', 'privateShareTranscriptTXT');
      }

      fetcher.load(resourceRoutes.transcripts + `?${qs}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, topic, type]);

  return (
    <div className="videoPlayerControls">
      <div className="videoPlayerControls__seekBar">
        <SeekBar
          onEnd={(value: number) => {
            seekTo(value);
          }}
          min={0}
          max={duration}
          value={currentTime}
        />
      </div>
      <div className="videoPlayerControls__actionsBar">
        <Tooltip
          label={isPlaying ? t('common.pause') : t('common.play')}
          className={`videoPlayerUtility__tooltip ${!showControls ? '-hidden' : ''}`}
        >
          <button
            type="button"
            className="videoPlayerControls__button"
            ref={playButtonRef}
            onClick={togglePlayback}
            autoFocus={autoFocus}
            aria-label={isPlaying ? t('videoPlayerControls.pauseVideo') : t('videoPlayerControls.playVideo')}
          >
            {isPlaying ? (
              <Pause24Solid className="videoPlayerControls__icon" />
            ) : (
              <IcFluentPlay24Filled className="videoPlayerControls__icon" />
            )}
          </button>
        </Tooltip>
        <p className="videoPlayerControls__time" aria-label={formatTime(Math.max(0, currentTime))}>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {formatTime(Math.max(0, currentTime))} | {formatTime(Math.max(0, duration))}
        </p>
        <Tooltip label={isMuted ? t('common.unMute') : t('common.mute')}>
          <button
            type="button"
            className="videoPlayerControls__button"
            onClick={handleToggleMute}
            aria-label={isMuted ? t('common.unMute') : t('common.mute')}
          >
            {isMuted ? <IcFluentSpeakerMute24Filled /> : <IcFluentSpeaker224Filled />}
          </button>
        </Tooltip>
        {hasClosedCaptions && (
          <Tooltip label={t('shared.immersiveReader')}>
            <button
              aria-label={t('videoPlayerControls.openImmersiveReader')}
              className="videoPlayerControls__button -ir"
              id={irId}
              onClick={() => invokeImmersiveReader(getName(), transcriptText, { idToFocusOnExit: irId })}
              type="button"
            >
              <IcFluentImmersiveReader24Regular />
            </button>
          </Tooltip>
        )}
        {hasClosedCaptions && (
          <Tooltip
            label={t('videoPlayerControls.closedCaptions')}
            className={`videoPlayerUtility__tooltip ${!showControls ? '-hidden' : ''}`}
          >
            <button
              type="button"
              className={`videoPlayerControls__button -cc${closedCaptions ? ' -active' : ''}`}
              onClick={handleToggleCaptions}
              aria-label={
                closedCaptions
                  ? t('videoPlayerControls.turnOffClosedCaptions')
                  : t('videoPlayerControls.turnOnClosedCaptions')
              }
              aria-pressed={closedCaptions}
            >
              <IcFluentClosedCaption24Filled className="videoPlayerControls__icon" />
            </button>
          </Tooltip>
        )}
        <Tooltip
          label={t('common.settings')}
          className={`videoPlayerUtility__tooltip ${!showControls ? '-hidden' : ''}`}
        >
          <button
            type="button"
            className={`videoPlayerControls__button -settings${showSettingsMenu ? ' -active' : ''}`}
            onClick={toggleSettingsMenu}
            aria-label={
              showSettingsMenu ? t('videoPlayerControls.closeSettings') : t('videoPlayerControls.openSettings')
            }
            ref={settingsButton}
          >
            <IcFluentSettings24Filled />
          </button>
        </Tooltip>
        {showSettingsMenu && (
          <SettingsMenu
            close={toggleSettingsMenu}
            isFullscreen={isFullscreen}
            isPlaying={isPlaying}
            looped={looped}
            onReportVideo={onReportVideo}
            playbackRate={playbackRate}
            portalTargetID={portalTargetID}
            setPlaybackRate={handleSetPlaybackRate}
            toggleFullscreen={handleToggleFullscreen}
            toggleLooped={toggleLooped}
            togglePlayback={handleTogglePlayback}
          />
        )}
        {!disableFullscreen && (
          <Tooltip
            label={isFullscreen ? t('videoPlayerControls.exitFullscreen') : t('videoPlayerControls.enterFullscreen')}
            className={`videoPlayerUtility__tooltip ${!showControls ? '-hidden' : ''}`}
          >
            <button
              type="button"
              className="videoPlayerControls__button -fullscreen"
              onClick={toggleFullscreen}
              aria-label={
                isFullscreen ? t('videoPlayerControls.exitFullscreen') : t('videoPlayerControls.enterFullscreen')
              }
            >
              {isFullscreen ? <FullscreenMinimize24Solid /> : <FullscreenMaximize24Solid />}
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default VideoPlayerControls;
