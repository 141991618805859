import { useGeneratedPrefixedId } from '@flipgrid/flipkit';

type Props = {
  className: string;
};

const Sparkles = ({ className }: Props) => {
  const id = useGeneratedPrefixedId('sparkles');

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 64 64"
      className={className}
    >
      <linearGradient
        id={`${id}-gradient-1`}
        gradientUnits="userSpaceOnUse"
        x1="-210"
        y1="275.0195"
        x2="-210"
        y2="276.0195"
        gradientTransform="matrix(42 0 0 -52 8843 14357)"
      >
        <stop offset="0" style={{ stopColor: '#FF9900' }} />
        <stop offset="0.022" style={{ stopColor: '#FF9C03' }} />
        <stop offset="0.274" style={{ stopColor: '#FFB91D' }} />
        <stop offset="0.5236" style={{ stopColor: '#FFCE31' }} />
        <stop offset="0.7678" style={{ stopColor: '#FFDB3C' }} />
        <stop offset="1" style={{ stopColor: '#FFDF40' }} />
      </linearGradient>
      <path
        className="sparkles__diamond"
        fill={`url(#${id}-gradient-1)`}
        d="M23,4c-1.816,20.226-5.507,23.868-21,26c15.493,2.132,19.184,5.774,21,26
	      c1.816-20.226,5.506-23.868,21-26C28.506,27.868,24.814,24.226,23,4z"
      />
      <linearGradient
        id={`${id}-gradient-2`}
        gradientUnits="userSpaceOnUse"
        x1="-205.125"
        y1="268.0381"
        x2="-205.125"
        y2="269.0381"
        gradientTransform="matrix(24 0 0 -26 4973 6997)"
      >
        <stop offset="0" style={{ stopColor: '#6FAD00' }} />
        <stop offset="0.0389" style={{ stopColor: '#73B202' }} />
        <stop offset="0.2625" style={{ stopColor: '#87CC0F' }} />
        <stop offset="0.4933" style={{ stopColor: '#96DE17' }} />
        <stop offset="0.734" style={{ stopColor: '#9EE91C' }} />
        <stop offset="1" style={{ stopColor: '#A1ED1E' }} />
      </linearGradient>
      <path
        className="sparkles__diamond"
        fill={`url(#${id}-gradient-2)`}
        d="M50,2c-1.037,10.113-3.146,11.935-12,13c8.854,1.065,10.963,2.887,12,13
	      c1.037-10.113,3.146-11.935,12-13C53.146,13.935,51.037,12.113,50,2z"
      />
      <linearGradient
        id={`${id}-gradient-3`}
        gradientUnits="userSpaceOnUse"
        x1="-205.125"
        y1="268.0381"
        x2="-205.125"
        y2="269.0381"
        gradientTransform="matrix(24 0 0 -26 4967 7031)"
      >
        <stop offset="0" style={{ stopColor: '#7D0C7A' }} />
        <stop offset="0.094" style={{ stopColor: '#930C90' }} />
        <stop offset="0.254" style={{ stopColor: '#B30DAE' }} />
        <stop offset="0.4208" style={{ stopColor: '#CC0DC6' }} />
        <stop offset="0.5949" style={{ stopColor: '#DD0ED8' }} />
        <stop offset="0.781" style={{ stopColor: '#E80EE2' }} />
        <stop offset="1" style={{ stopColor: '#EB0EE5' }} />
      </linearGradient>
      <path
        className="sparkles__diamond"
        fill={`url(#${id}-gradient-3)`}
        d="M44,36c-1.037,10.113-3.146,11.934-12,13c8.854,1.066,10.963,2.888,12,13
	      c1.037-10.112,3.146-11.934,12-13C47.146,47.934,45.037,46.113,44,36z"
      />
    </svg>
  );
};

export default Sparkles;
