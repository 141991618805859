import { Button, ButtonSubmit, IcFluentDismiss24Regular, Loader, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SuccessPostToTopicModal, { links as successPostToTopicModalStyles } from './SuccessPostToTopicModal';
import Combobox from '../../FkWrappers/Combobox';
import TopicImageCircle, { links as topicImageCircleStyles } from '~/components/MyVideos/TopicImageCircle';
import InfiniteScroll from '~/components/Utility/InfiniteScroll';
import actions from '~/constants/actions';
import resourceRoutes from '~/constants/resourceRoutes';
import postToTopicModalStyles from '~/styles/components/Modals/MyVideos/PostToTopicModal.css';

import type { OnRequestCloseType, ShowModalType } from '@flipgrid/flipkit';
import type { SetStateAction } from 'react';
import type { FlipResponse, Metadata, Topic } from 'types';

export const links = () => [
...topicImageCircleStyles(),
...successPostToTopicModalStyles(),
{ rel: 'stylesheet', href: postToTopicModalStyles }];


type Props = {
  onRequestClose: OnRequestCloseType;
  response: FlipResponse;
  setTopics: (topics: SetStateAction<Topic[] | undefined>) => void;
  setTopicsMetadata: (metadata: Metadata) => void;
  setVideos: (videos: SetStateAction<FlipResponse[] | undefined>) => void;
  showModal: ShowModalType;
  topics?: Topic[];
  topicsMetadata?: Metadata;
};

const PostToTopicModal = ({
  onRequestClose,
  response,
  setTopics,
  setTopicsMetadata,
  setVideos,
  showModal,
  topics,
  topicsMetadata
}: Props) => {
  const { t } = useTranslation();
  const [topicList, setTopicList] = useState(topics);
  const [topicListMetadata, setTopicListMetadata] = useState(topicsMetadata);
  const [selectedTopic, setSelectedTopic] = useState<Topic | undefined>(undefined);
  const pagination = topicListMetadata?.pagination;
  const topicListFetcher = useFetcher();
  const postFetcher = useFetcher();

  useEffect(() => {
    if (postFetcher.type === 'done' && !postFetcher.data?.data?.error && selectedTopic) {
      setVideos(
        (prevVideos) =>
        prevVideos?.map((v) => {
          if (v.id === postFetcher.data.data.id) return postFetcher.data.data;
          return v;
        })
      );
      showModal(SuccessPostToTopicModal, { topic: selectedTopic, responseImage: response.image_url });
    }
  }, [postFetcher, selectedTopic, setVideos, showModal, response.image_url]);

  const updateTopicList = useCallback(
    (newTopics: Topic[], newTopicsMetadata: Metadata, append: boolean) => {
      if (append) {
        setTopics((prevTopics) => [...(prevTopics || []), ...newTopics]);
        setTopicList((prevTopics) => [...(prevTopics || []), ...newTopics]);
      } else {
        setTopics(newTopics);
        setTopicList(newTopics);
      }
      setTopicsMetadata(newTopicsMetadata);
      setTopicListMetadata(newTopicsMetadata);
    },
    [setTopics, setTopicsMetadata]
  );

  useEffect(() => {
    if (topicListFetcher.type === 'done' && topicListFetcher.data && !topicListFetcher.data?.data?.error) {
      const metadata = topicListFetcher.data.metadata;
      updateTopicList(topicListFetcher.data.data, metadata, metadata?.pagination?.current_page !== 1);
    }
  }, [topicListFetcher, updateTopicList]);

  const fetchTopics = (options?: {page?: number;term?: string;}) => {
    if (topicListFetcher.state === 'idle') {
      const params = ({
        order_by: 'updated_at',
        page: '1'
      } as {[key: string]: string;});

      if (options?.page) {
        params.page = options.page?.toString();
      }

      if (options?.term) params.q = options.term;

      const formattedParams = new URLSearchParams(params);
      topicListFetcher.load(resourceRoutes.myTopics + `?${formattedParams.toString()}`);
    }
  };

  const loadMoreTopics = () => {
    if (pagination) {
      const page = pagination.current_page + 1;
      fetchTopics({ page });
    }
  };

  useEffect(() => {
    if (!topicList) {
      fetchTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTopics = () => {
    const htmlObjects: TSFix[] = [];

    if (topicList)
    topicList.forEach((topic: Topic) => {
      htmlObjects.push(
        <Combobox.Option key={topic.id} value={topic.id.toString()}>
            {/* @ts-ignore */}
            <div className="postToTopicModal__optionWrapper">
              <TopicImageCircle topic={topic} />
              <div className="postToTopicModal__text">
                <p className="fk-m0">{topic.grid_name}</p>
                <h3 className="fk-m0">{topic.title}</h3>
              </div>
            </div>
          </Combobox.Option>
      );
    });

    if (pagination && pagination.total_pages !== 1 && pagination.total_pages > pagination.current_page)
    htmlObjects.push(
      <Combobox.Option key="loading" value="loading-2">
          {/* @ts-ignore */}
          <div className="postToTopicModal__loader">
            <Loader />
            <InfiniteScroll
            pagination={pagination}
            fetcherState={topicListFetcher.state}
            load={loadMoreTopics}
            rootMargin="500px 0px" />

          </div>
        </Combobox.Option>
    );

    return htmlObjects;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTopics = useCallback(
    debounce((term: string) => {
      if (term.length > 2) {
        fetchTopics({ term });
      } else if (term.length === 0) {
        fetchTopics();
      }
    }, 1000),
    []
  );

  const submit = () => {
    const formData = new FormData();
    formData.append('response_id', response.id.toString());
    formData.append('topic_id', selectedTopic?.id.toString() || '0');
    formData.append('grid_id', selectedTopic?.grid_id.toString() || '0');
    formData.append('_action', actions.postToTopic);
    postFetcher.submit(formData, { method: 'post', action: resourceRoutes.postToTopic });
  };

  return (
    <Modal onClose={onRequestClose}>
      <div className="postToTopicModal__wrapper">
        <h1 className="fk-modalHeader">{t('shared.postToTopic')}</h1>
        {response.image_url &&
        <img src={`${response.image_url}?size=large`} alt="" className="postToTopicModal__responseImage" />}

        <p className="fk-mb0">{t('postToTopicModal.postToModalDesc')}</p>
        <p className="fk-mt0">{t('postToTopicModal.pickATopic')}</p>
      </div>
      {topicList?.length && !selectedTopic ?
      <Combobox
        label={t('shared.postTo')}
        name="topic_list"
        onSelect={(value: string) => {
          const tempTopic = topicList.filter((item: Topic) => item.id.toString() === value)[0];
          setSelectedTopic(tempTopic);
        }}
        onChange={(e) => {
          searchTopics(e.target.value);
        }}
        placeholder={t('postToTopicModal.chooseTopic')}>

          {getTopics()}
        </Combobox> :
      selectedTopic ?
      <div className="postToTopicModal__selectedTopic">
          <TopicImageCircle topic={selectedTopic} />
          <div className="postToTopicModal__text">
            <p className="fk-m0">{selectedTopic.grid_name}</p>
            <h2 className="fk-h3 fk-m0">{selectedTopic.title}</h2>
          </div>
          <Button
          theme="tertiary"
          size="26"
          aria-label={t('postToTopicModal.deselectTopic')}
          data-testid="postToTopicModal__button__remove"
          className="postToTopicModal__dismissIcon"
          onClick={() => {
            setSelectedTopic(undefined);
          }}
          icon={<IcFluentDismiss24Regular width="16" height="16" />} />

        </div> :

      <Loader container />}

      <Modal.Actions className="mt2">
        <ButtonSubmit
          data-testid="postToTopicModal__button__postTo"
          type="button"
          onClick={submit}
          loading={postFetcher.state !== 'idle'}
          disabled={!selectedTopic}>

          {t('shared.postToTopic')}
        </ButtonSubmit>
      </Modal.Actions>
    </Modal>);

};

export default PostToTopicModal;