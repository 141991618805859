import { localStorageKeys } from '../constants/constants';

function get(key: string) {
  try {
    const item = window.localStorage.getItem(key);
    return item;
  } catch {
    return null;
  }
}

function set(key: string, item: TSFix) {
  try {
    const thing = window.localStorage.setItem(key, item);
    return thing;
  } catch {
    return null;
  }
}

export function getStorageItem(key: string) {
  let container: TSFix = get(localStorageKeys.container);
  if (container === null) return null;
  container = JSON.parse(container);
  return container[key];
}

export function setStorageItem(key: string, value: TSFix) {
  let container: TSFix = get(localStorageKeys.container);
  // Using the shorthand { [key]: value } was breaking IE...
  const objectToSet: TSFix = {};
  objectToSet[key] = value;
  if (container === null) return set(localStorageKeys.container, JSON.stringify(objectToSet));

  container = JSON.parse(container);
  return set(localStorageKeys.container, JSON.stringify({ ...container, ...objectToSet }));
}
