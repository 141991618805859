import { IcFluentCheckmark24Regular, IcFluentChevronLeft24Regular } from '@flipgrid/flipkit';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { videoSpeeds } from '../../constants/constants';
import useMenuNavigation from '~/components/WebPlayer/hooks/useMenuNavigation';

type Props = {
  closeMenu: () => void;
  playbackRate: number;
  setMenu: (arg: string) => void;
  setPlaybackRate: (speed: number) => void;
};

function PlaybackSpeedPanel({ closeMenu, playbackRate, setMenu, setPlaybackRate }: Props) {
  const { t } = useTranslation();
  const panel = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!panel.current) return;
    const active = panel.current.querySelector('.-active') as HTMLElement;
    if (active && active.focus) active.focus();
  }, []);

  useMenuNavigation(panel, { escape: closeMenu, backward: () => setMenu('HOME') });

  return (
    <div className="videoPlayerUtility__panel playbackSpeedPanel" ref={panel}>
      <button
        type="button"
        className="playbackSpeedPanel__back videoPlayerUtility__panelButton"
        aria-label={t('playbackSpeedPanel.backToMainMenu')}
        aria-expanded="true"
        autoFocus
        onClick={() => {
          setMenu('HOME');
        }}
      >
        <>
          <IcFluentChevronLeft24Regular className="playbackSpeedPanel__caret" />
          {t('playbackSpeedPanel.playbackSpeed')}
        </>
      </button>
      {videoSpeeds.map((speed, index) => {
        const playbackOption = index + 1;
        return (
          <button
            key={speed.toString()}
            type="button"
            className="videoPlayerUtility__panelButton"
            aria-label={t('playbackSpeedPanel.changePlaybackSpeedNums', { playbackOption, speed })}
            aria-pressed={playbackRate === speed}
            onClick={() => {
              setPlaybackRate(speed);
            }}
          >
            <>
              <IcFluentCheckmark24Regular
                className={playbackRate === speed ? 'iconFill__brand' : 'iconFill__transparent'}
              />
              {speed === 1 ? t('common.normal') : speed.toFixed(2)}
            </>
          </button>
        );
      })}
    </div>
  );
}

export default PlaybackSpeedPanel;
