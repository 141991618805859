import { CSSTransition } from 'react-transition-group';

import type { ReactNode } from 'react';

type Props = {
  active: boolean;
  children: ReactNode;
  className?: string;
  onEnter?: (arg0: HTMLElement) => void;
};

const Panel = ({ active, children, className, onEnter }: Props) => {
  return (
    <CSSTransition in={active} timeout={250} classNames={className} onEnter={onEnter} mountOnEnter unmountOnExit>
      {children}
    </CSSTransition>
  );
};
export default Panel;
