import { useEventListener } from '@flipgrid/flipkit';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  arrowKeyStepSize?: number;
  min: number;
  max: number;
  onChange?: (event: React.ChangeEvent) => void;
  onEnd: (arg0: number) => void;
  step?: 'any';
  value: number;
};

// TODO: Localize this file

const SeekBar = ({ arrowKeyStepSize = 10, min, max, onChange, onEnd, step = 'any', value: valueProp }: Props) => {
  const { t } = useTranslation();
  const [userIsInteracting, setUserIsInteracting] = useState(false);
  const [value, setValue] = useState(valueProp);
  const seekbar = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!userIsInteracting) setValue(valueProp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp]);

  useEffect(() => {
    if (seekbar.current) seekbar.current.style?.setProperty('--value', `0`);
  }, []);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.target !== seekbar.current) return;

    // Skip if not arrow keys
    if (e.keyCode < 37 || e.keyCode > 40) return;

    // Stop other arrow key events from firing
    // @ts-ignore
    e.stopImmediatePropagation();

    // Slightly opinionated in that up/right means increase and down/left means decrease
    if (e.keyCode === 38 || e.keyCode === 39) onEndIncrement(e, arrowKeyStepSize);
    else onEndIncrement(e, -arrowKeyStepSize);
  };

  useEventListener('keydown', onKeyDown, true);

  useEffect(() => {
    if (seekbar.current) seekbar.current.style.setProperty('--value', `${(valueProp / max - min) * 100}%`);
  }, [valueProp, max, min]);

  const onChangeFunction = (e: TSFix) => {
    setValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  // additional is used so that we can consolidate the arrow key movement and the mouse
  // drag movement into one event for the sake of the consumer.
  const onEndIncrement = (e: TSFix, additional = 0) => {
    // Delay stopping the userIsInteracting cancel to give the value a chance to update
    if (onEnd) onEnd(Number(e.target.value) + additional);
    setTimeout(() => {
      setUserIsInteracting(false);
    }, 250);
  };

  const onStart = (e: TSFix) => {
    if (e.button !== 0) return;

    // This allows the input to be both user controlled and programmatically controlled
    setUserIsInteracting(true);
  };

  const formatAriaValueText = () => {
    const current = Math.round(Math.max(0, value));
    const total = Math.round(max);

    const [currentMinutes, currentSeconds] = [Math.floor(current / 60), Math.floor(current % 60)];
    const [totalMinutes, totalSeconds] = [Math.floor(total / 60), Math.floor(total % 60)];

    const currentString = `${currentMinutes}:${currentSeconds < 10 ? '0' + currentSeconds : currentSeconds}`;
    const totalString = `${totalMinutes}:${totalSeconds < 10 ? '0' + totalSeconds : totalSeconds}`;

    return `${currentString} of ${totalString}`;
  };

  return (
    <input
      type="range"
      className="seekbar"
      value={value}
      min={min}
      max={max}
      step={step}
      onMouseDown={onStart}
      onChange={onChangeFunction} // Not sure what this is for
      onMouseUp={onEndIncrement}
      onTouchStart={onStart}
      onTouchMove={onChangeFunction}
      onTouchEnd={onEndIncrement}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      aria-valuetext={formatAriaValueText()}
      aria-label={t('seekBar.seekBarScrubber')}
      ref={seekbar}
    />
  );
};

export default SeekBar;
