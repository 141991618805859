import { Button, Popover } from '@flipgrid/flipkit';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RecordResponseBtn from '../Share/RecordResponseBtn';
import { reactionType } from '~/constants/videoReactionConstants';
import GreenScreenIcon from '~/svg/GreenScreenIcon';
import RemixIcon from '~/svg/RemixIcon';
import StitchIcon from '~/svg/StitchIcon';

import type { Topic } from 'types';

type VideoReactionDialogProps = {
  isGroupLead: boolean;
  topic: Topic;
  topicResponseUrl: string;
  onClick: () => void;
  onGreenScreenClick: () => void;
  onStitchClick: () => void;
};

const VideoReactionDialog = ({
  isGroupLead,
  topic,
  topicResponseUrl,
  onClick,
  onGreenScreenClick,
  onStitchClick,
}: VideoReactionDialogProps) => {
  const { t } = useTranslation();
  const [isReactionPopoverVisible, setIsReactionPopoverVisible] = useState(false);
  const reactionButtonRef = useRef(null);

  const toggleIsReactionPopoverVisible = useCallback(() => {
    setIsReactionPopoverVisible(prev => !prev);
  }, [setIsReactionPopoverVisible]);

  return (
    <div>
      <Button
        aria-describedby={isReactionPopoverVisible ? 'videoReactionDialogPopover' : null}
        aria-label={t('videoReactionDialog.videoRemix')}
        data-testid="videoReactionDialog__button__remixButton"
        icon={<RemixIcon width={32} height={32} />}
        onClick={() => {
          toggleIsReactionPopoverVisible();
          onClick();
        }}
        ref={reactionButtonRef}
        theme="overlay"
        variant="button"
      />
      <Popover
        id="videoReactionDialogPopover"
        isVisible={isReactionPopoverVisible}
        onDismiss={toggleIsReactionPopoverVisible}
        targetRef={reactionButtonRef}
        useArrow={false}
      >
        <div className="videoReactionDialog__reactionButtonContainer">
          <RecordResponseBtn
            buttonIcon={<StitchIcon width={20} height={20} />}
            buttonText={t('videoReactionDialog.stitch')}
            buttonTheme="transparent"
            className="videoReactionDialog__reactionButton"
            isGroupLead={isGroupLead}
            onClick={onStitchClick}
            topic={topic}
            entity={{ topicResponseUrl, reactionType: reactionType.STITCH }}
          />

          <RecordResponseBtn
            buttonIcon={<GreenScreenIcon width={20} height={20} />}
            buttonText={t('videoReactionDialog.greenScreen')}
            buttonTheme="transparent"
            className="videoReactionDialog__reactionButton"
            isGroupLead={isGroupLead}
            onClick={onGreenScreenClick}
            topic={topic}
            entity={{ topicResponseUrl, reactionType: reactionType.GREEN_SCREEN }}
          />
        </div>
      </Popover>
    </div>
  );
};
export default VideoReactionDialog;
