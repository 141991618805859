import { Button, IcFluentMegaphone24Filled, LinkBase, Popover, useGeneratedPrefixedId } from '@flipgrid/flipkit';
import { Link as RemixLink } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HorizontalLine, { links as horizontalLineStyles } from '../Layout/HorizontalLine';
import Tile, { links as tileStyles } from '../Layout/Tile';
import routes from '~/constants/routes';
import sanitize from '~/helper/sanitize';
import topicCardStyles from '~/styles/components/Utility/Buttons/TopicCard.css';

import type { Topic } from 'types';

export const links = () => [...horizontalLineStyles(), ...tileStyles(), { rel: 'stylesheet', href: topicCardStyles }];

type Props = {
  topic: Topic;
};

const TopicCard = ({ topic }: Props) => {
  const { t } = useTranslation();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const topicCardRef = useRef<HTMLButtonElement>(null);
  const linkRef = useRef<HTMLLinkElement>(null);
  const popoverId = useGeneratedPrefixedId('topic-card-popover');

  useEffect(() => {
    if (isPopoverVisible && linkRef.current) linkRef.current.focus();
  }, [isPopoverVisible]);

  const getExtraTiles = () => {
    const extraTiles = [];
    for (let i = 0; i < 8 - topic.recent_responses.length; i += 1) {
      extraTiles.push(<Tile className="tile__medium -background" key={`emptyTile-${i}`} />);
    }

    return extraTiles;
  };

  return (
    <>
      <Button
        aria-describedby={isPopoverVisible ? popoverId : null}
        className="topicCard"
        data-testid="topicCard__button__topicCard"
        icon={<IcFluentMegaphone24Filled className="tilt" />}
        onClick={() => setIsPopoverVisible((prev) => !prev)}
        ref={topicCardRef}
        size="36"
        theme="overlay"
        truncate>

        {topic.title}
      </Button>
      <Popover
        className="topicCard__popover"
        childClassName="topicCard__popoverChild"
        data-testid="topicCard__popover__topicCard"
        id={popoverId}
        isVisible={isPopoverVisible}
        onDismiss={() => setIsPopoverVisible(false)}
        placement="bottom"
        targetRef={topicCardRef}
        useArrow={false}>

        <LinkBase
        // @ts-ignore
        as={RemixLink}
        data-testid="topicCard__link__topicCard"
        ref={linkRef}
        to={routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(topic.grid_id, topic.id)}>

          <h1 className="topicCard__header">
            <>
              <IcFluentMegaphone24Filled className="tilt iconFill__fg-3" />
              {t('topicCard.topicCaps')}
            </>
          </h1>
          <h2 className="topicCard__title color__fg-1">{topic.title}</h2>
          <p
            className="fk-editorInner m0 fk-helperText"
            dangerouslySetInnerHTML={{ __html: sanitize.strict(topic.text) }} // eslint-disable-line
          />
          <HorizontalLine className="topicCard__horizontalLine" />
          <h3 className="topicCard__responses">{t('shared.numResponses', { count: topic.response_count })}</h3>
          {topic.recent_responses && topic.recent_responses.length > 0 &&
          <div className="topicCard__tileLayout">
              {topic.recent_responses.map((response) => {
              return <Tile className="tile__medium" src={response.image_url} key={response.id + '-topicCardTile'} />;
            })}
              {topic.recent_responses.length < 8 && getExtraTiles()}
            </div>}

        </LinkBase>
      </Popover>
    </>);

};

export default TopicCard;