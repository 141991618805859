import { Button, Modal } from '@flipgrid/flipkit';
import { Trans, useTranslation } from 'react-i18next';

import Link from '~/components/FkWrappers/Link';
import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  onRequestClose: OnRequestCloseType;
};

const NoMixtapeFoundModal = ({ onRequestClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal show onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('noMixtapeFoundModal.noMixtapeFound')}</h1>
      <div className="fk-modalBody">
        <p>
          <Trans i18nKey="shared.mixtapeExplanationLong">
            Mixtapes make it easy to create collections of videos from any of your Topics.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="noMixtapeFoundModal.createNewMixtapeToAddVideo">
            Create a new{' '}
            <Link to={routes.MIXTAPES} onClick={onRequestClose} data-testid="noMixtapeFoundModal__link__mixtapes">
              Mixtape
            </Link>{' '}
            to add the video to or return to your topic.
          </Trans>
        </p>
      </div>
      <Modal.Actions className="mt2">
        <Button data-testid="noMixtapeFoundModal__button__cancel" onClick={onRequestClose}>
          {t('common.close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NoMixtapeFoundModal;
