import { ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../FkWrappers/Input';
import TextArea from '../FkWrappers/TextArea';
import routes from '~/constants/routes';
import { formatName } from '~/helper/formatting';
import { isValidUrl } from '~/helper/helper';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, FlipResponse } from 'types';

type Props = {
  response: FlipResponse;
  onRequestClose: OnRequestCloseType;
};

const EditMyVideoDetailsModal = ({ response, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<ApiResponse<FlipResponse> | { error: string }>();
  const [enable, setEnable] = useState(false);
  const [error, setError] = useState('');

  const { title, link, id } = response;
  const name = formatName(response);

  useEffect(() => {
    if (fetcher.type === 'done')
      if (typeof fetcher?.data?.error === 'string') {
        setError(t(fetcher?.data?.error));
      } else {
        onRequestClose();
      }
  }, [fetcher, onRequestClose, t]);

  const onFormChange = (e: { currentTarget: HTMLFormElement | undefined }) => {
    const formData = new FormData(e.currentTarget);
    const linkValue = formData.get('link');

    if (linkValue && !isValidUrl((linkValue as string) || '')) {
      setError(t('shared.validLink'));
      setEnable(false);
    } else {
      setError('');
      setEnable(formData.get('title') !== title || linkValue !== link);
    }
  };

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('shared.editCreatorsResponseDetails', { name })}</h1>
      <fetcher.Form onChange={onFormChange} method="put" action={routes.MY_VIDEOS}>
        <div className="fk-modalPanel mt1">
          <TextArea
            label={t('common.description')}
            placeholder={t('shared.updateDescriptionResponse')}
            maxLength={100}
            name="title"
            defaultValue={title ?? null}
            data-testid="editMyVideosDetailsModal__textarea__description"
            counter={title ? title.length : '0'}
            rows="2"
          />
          <Input
            label={t('common.link')}
            placeholder={t('shared.updateLinkResponse')}
            maxLength={255}
            defaultValue={link ?? undefined}
            name="link"
            data-testid="editMyVideosDetailsModal__input__link"
            error={error}
          />
        </div>
        <Modal.Actions className="mt2">
          <ButtonSubmit
            name="_action"
            value="updateVideoDetails"
            data-testid="editMyVideosDetailsModal__button__update"
            loading={fetcher.state !== 'idle'}
            disabled={!enable}
          >
            {t('common.save')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="videoId" value={id} />
      </fetcher.Form>
    </Modal>
  );
};

export default EditMyVideoDetailsModal;
