import { Button, ButtonSubmit, Loader, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Combobox from '../../FkWrappers/Combobox';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import { formatName } from '~/helper/formatting';

import type { ApiResponse, FlipResponse, Group, Job, Topic } from 'types';

type Props = {
  onRequestClose: () => void;
  response: FlipResponse;
  topic: Topic;
};

type ClientErrors = { group_name: boolean; topic_name: boolean };

type ResponseErrors = {
  data: { error: boolean }; // api error
  errors?: ClientErrors;
};

const MoveResponseModal = ({ onRequestClose, response, topic }: Props) => {
  const { t } = useTranslation();
  const groupsFetcher = useFetcher<ApiResponse<Group[]>>();
  const topicsFetcher = useFetcher<ApiResponse<Topic[]>>();
  const fetcher = useFetcher<Job | ResponseErrors>();
  const [gridResponse, setGridResponse] = useState<ApiResponse<Group[]>>();
  const [topicResponse, setTopicResponse] = useState<ApiResponse<Topic[]>>();
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedTopic, setSelectedTopic] = useState<Topic>();
  const delimiter = '·';

  const clientErrors = (fetcher.data as ResponseErrors)?.errors;
  const isSubmitting = fetcher.state !== 'idle';
  const submissionSuccess = fetcher.type === 'done' && !fetcher.submission && !fetcher.data;
  const submissionPending = fetcher.type === 'done' && (fetcher.data as Job)?.status === 'working';
  const submissionError = fetcher.type === 'done' && (fetcher.data as Job)?.status === 'failed';

  function parseSelection<T>(displaySelection: string, list: T[]) {
    const selectedVanityToken = displaySelection
      .substring(displaySelection.lastIndexOf(delimiter) + 1, displaySelection.length)
      .trim();
    const selection: T = (list as TSFix).find((group: Group | Topic) => group.vanity_token === selectedVanityToken);
    return selection;
  }

  const clearClientErrors = () => {
    fetcher.data = undefined;
  };

  const loadTopics = (groupId: number) => {
    if (topicResponse) setTopicResponse(undefined);

    const params = {
      group_id: groupId.toString(),
      manage: 'true',
      order_by: '-title',
      per_page: '500',
      standalone_only: 'true',
    };

    const formattedParams = new URLSearchParams(params);
    topicsFetcher.load(`${resourceRoutes.myTopics}?${formattedParams.toString()}`);
  };

  const buildOptionValue = ({
    name,
    responseCount,
    vanity_token,
  }: {
    name: string;
    responseCount: number;
    vanity_token: string;
  }) => {
    const count = t('shared.numResponses', { count: responseCount });
    return `${name} ${delimiter} ${count} ${delimiter} ${vanity_token}`;
  };

  useEffect(() => {
    const shouldLoadGroups = groupsFetcher.state === 'idle' && !groupsFetcher.data;

    if (shouldLoadGroups) {
      const params = {
        load_all: 'true',
        per_page: '250',
        standalone: 'true',
      };
      const formattedParams = new URLSearchParams(params);
      groupsFetcher.load(`${resourceRoutes.myGroups}?${formattedParams.toString()}`);
    }
  }, [groupsFetcher]);

  useEffect(() => {
    if (groupsFetcher.data) setGridResponse(groupsFetcher.data);
    if (topicsFetcher.data) setTopicResponse(topicsFetcher.data);
  }, [groupsFetcher.data, topicsFetcher.data]);

  if (submissionError)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('common.oops')}</h1>
        <p className="fk-modalBody">{t('moveResponseModal.errorMovedResponse')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="moveResponseModal__button__closeError">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionPending)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('shared.stillWorking')}</h1>
        <p className="fk-modalBody">{t('moveResponseModal.duplicationTopicsInProgress')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="moveResponseModal__button__closePending">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionSuccess)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('moveResponseModal.responseMoved')}</h1>
        <p className="fk-modalBody">{t('moveResponseModal.youHaveBeenRedirectedTopic')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="moveResponseModal__button__closeSuccess">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form method="post" action={routes.GROUPS_ID_TOPICS_FUNC(topic.grid_id)} onSubmit={clearClientErrors}>
        <h1 className="fk-modalHeader">{t('moveResponseWrapper.moveNumberResponses', { count: 1 })}</h1>
        <p className="fk-modalBody">
          <Trans i18nKey="moveResponseModal.selectDestinationFromName" responseName={formatName(response)}>
            Select where you would like to move the response from
            <strong>{{ responseName: formatName(response) }}</strong>.
          </Trans>
        </p>
        {gridResponse?.data && gridResponse?.data.length > 0 ? (
          <div className="mt1 mb1">
            <Combobox
              name="move_response_groups"
              label={t('shared.selectAGroup')}
              placeholder={t('shared.selectOrTypeToSearch')}
              onSelect={selectionString => {
                const groupSelection = parseSelection(selectionString, gridResponse.data as Group[]);
                setSelectedGroup(groupSelection);
                loadTopics(groupSelection.id);
                clearClientErrors();
              }}
              error={
                clientErrors?.group_name && fetcher.state !== 'submitting'
                  ? t('shared.selectDestinationGroup')
                  : undefined
              }
            >
              {gridResponse?.data
                .filter(g => g.topic_count > 0)
                .map(grid => {
                  const gridValue = buildOptionValue({
                    name: grid.name,
                    responseCount: grid.all_response_count ?? 0,
                    vanity_token: grid.vanity_token,
                  });
                  return <Combobox.Option value={gridValue} key={grid.id} />;
                })}
            </Combobox>
          </div>
        ) : (
          <Loader container />
        )}

        {selectedGroup &&
          (topicResponse && topicResponse.data.length > 0 ? (
            <div className="mb1">
              <Combobox
                name="move_response_topics"
                label={t('shared.selectATopic')}
                onSelect={selectionString => {
                  if (!topicsFetcher.data) return;
                  const selection: Topic = parseSelection(selectionString, topicsFetcher.data.data);
                  setSelectedTopic(selection);
                  clearClientErrors();
                }}
                placeholder={t('shared.selectOrTypeToSearch')}
                error={
                  clientErrors?.topic_name && fetcher.state !== 'submitting'
                    ? t('shared.pleaseSelectADestinationTopic')
                    : undefined
                }
              >
                {topicResponse.data
                  .filter(topicItem => topic.id !== topicItem.id) // filter out current topic
                  .map(topicItem => {
                    const displayValue = buildOptionValue({
                      name: topicItem.title,
                      responseCount: topicItem.all_response_count,
                      vanity_token: topicItem.vanity_token,
                    });
                    return <Combobox.Option value={displayValue} key={topicItem.id} />;
                  })}
              </Combobox>
            </div>
          ) : (
            <Loader container />
          ))}

        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="moveResponseModal__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="moveResponse"
            data-testid="moveResponseModal__button__move"
            loading={isSubmitting}
          >
            {t('common.move')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="ids" value={[response.id.toString()]} />
        <input type="hidden" name="group_id" value={selectedTopic?.grid_id ?? ''} />
        <input type="hidden" name="topic_id" value={selectedTopic?.id ?? ''} />
        <input type="hidden" name="group_name" value={selectedGroup?.name ?? ''} />
        <input type="hidden" name="topic_name" value={selectedTopic?.title ?? ''} />
      </fetcher.Form>
    </Modal>
  );
};

export default MoveResponseModal;
