import { FeatureFlag as FlipKitFeatureFlag } from '@flipgrid/flipkit';
import { useContext } from 'react';

import GlobalContext from '~/contexts/globalContext';

import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  match: string;
  showWhenOff?: boolean;
};

const FeatureFlag = ({ children, match, showWhenOff }: Props) => {
  const { featureFlags } = useContext(GlobalContext);

  return (
    <FlipKitFeatureFlag featureFlags={featureFlags} match={match} showWhenOff={showWhenOff}>
      {children}
    </FlipKitFeatureFlag>
  );
};

export default FeatureFlag;
