import { Button, Dropdown, IcFluentShareIos24Regular, Tooltip } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ShareModal, { links as shareModalStyles } from '~/components/Modals/Share/ShareModal';
import { removeHttpsFromUrl } from '~/helper/helper';

import type { ShowModalType, themesForAllVariants, themesForNonTextVariants } from '@flipgrid/flipkit';
import type { GroupAccessControls, ShareEntity } from 'types';

export const links = () => [...shareModalStyles()];

type Props = {
  accessControl?: GroupAccessControls;
  className?: string;
  disabled?: boolean;
  loadShareModalOnInit?: boolean;
  onSelect?: () => void;
  showModal: ShowModalType;
  theme?: themesForNonTextVariants | themesForAllVariants;
  'aria-label'?: string;
  name?: string;
  image_url?: string;
  mixtapeToken?: string;
} & (
  | {
      displayType: 'dropdown' | 'icon' | 'text';
      onClick?: never;
    }
  | {
      displayType: 'iconPlaceholder';
      onClick: () => void;
    }
) & { entity: ShareEntity };

const ShareButton = ({
  accessControl,
  className = '',
  disabled,
  displayType,
  entity,
  loadShareModalOnInit = false,
  onSelect,
  showModal,
  theme = 'secondary',
  'aria-label': ariaLabel,
  name,
  onClick,
  image_url,
  mixtapeToken,
}: Props) => {
  const { t } = useTranslation();
  const [routeData] = useMatches();
  const [shouldShowModalOnInit, setShouldShowModalOnInit] = useState(loadShareModalOnInit);
  const rootPath = `${removeHttpsFromUrl(routeData.data.env.CLIENT_SHARE_URL)}`;

  const typeProps = useMemo(() => {
    switch (entity.type) {
      case 'Comment':
        return {
          buttonText: t('shared.shareComment'),
        };
      case 'Group': {
        return {
          buttonText: t('shared.shareGroup'),
          token: entity.item.vanity_token,
        };
      }
      case 'MyVideos':
        return {
          buttonText: t('shared.shareLink'),
          token: entity.item.vanity_token,
        };
      case 'Response':
        return {
          buttonText: t('shared.shareResponse'),
          token: entity.item.vanity_token,
        };
      case 'Topic':
        return {
          buttonText: t('shared.shareTopic'),
          token: entity.item.vanity_token,
        };
      case 'Mixtape':
        return {
          buttonText: t('shared.shareMixtape'),
          token: entity.item.vanity_token,
        };
      case 'MixtapeResponse':
        return {
          buttonText: t('shared.shareLink'),
          token: mixtapeToken,
        };
      default:
        return {
          buttonText: t('common.share'),
        };
    }
  }, [entity.type, entity.item, t, mixtapeToken]);

  const openModal = useCallback(() => {
    if (entity.type === 'Topic')
      showModal(ShareModal, {
        accessControl,
        entity,
        shareBaseUrl: rootPath,
        token: typeProps.token || '',
        name,
        image_url,
      });
    else
      showModal(ShareModal, {
        accessControl,
        entity,
        shareBaseUrl: rootPath,
        token: typeProps.token || '',
      });
  }, [accessControl, entity, image_url, name, rootPath, showModal, typeProps.token]);

  useEffect(() => {
    if (shouldShowModalOnInit) openModal();
    setShouldShowModalOnInit(false);
  }, [shouldShowModalOnInit, openModal]);

  if (displayType === 'dropdown')
    return (
      <Dropdown.Item
        className={className}
        data-testid="shareButton__button__dropdown"
        disabled={disabled}
        icon={<IcFluentShareIos24Regular />}
        onClick={(e: React.KeyboardEvent | React.MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onSelect={() => {
          openModal();
          if (onSelect) onSelect();
        }}
      >
        {typeProps.buttonText}
      </Dropdown.Item>
    );

  if (displayType === 'iconPlaceholder')
    return (
      <Tooltip label={t('common.share')}>
        <Button
          aria-label={ariaLabel || t('common.share')}
          className={className}
          data-testid="shareButton__button__icon"
          disabled={disabled}
          icon={<IcFluentShareIos24Regular />}
          onClick={onClick}
          theme={theme}
          size="36"
        />
      </Tooltip>
    );

  // Defaults to icon display
  return (
    <Tooltip label={t('common.share')}>
      <Button
        aria-label={ariaLabel || t('common.share')}
        className={className}
        data-testid="shareButton__button__icon"
        disabled={disabled}
        icon={<IcFluentShareIos24Regular />}
        onClick={() => {
          openModal();
          if (onSelect) onSelect();
        }}
        theme={theme}
        size="36"
      >
        {displayType === 'text' ? t('common.share') : undefined}
      </Button>
    </Tooltip>
  );
};

export default ShareButton;
