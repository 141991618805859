import { ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../FkWrappers/Input';
import TextArea from '../FkWrappers/TextArea';
import routes from '~/constants/routes';
import { formatName } from '~/helper/formatting';
import { isValidUrl } from '~/helper/helper';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { FlipResponse } from 'types';

type Props = {
  response: FlipResponse;
  onRequestClose: OnRequestCloseType;
};

const EditResponsesDetailsModal = ({ response, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const [enable, setEnable] = useState(false);
  const [error, setError] = useState('');
  const { title, link, id, grid_id, topic_id } = response;
  const name = formatName(response);

  useEffect(() => {
    const hasResponseData = !!fetcher.data;
    if (fetcher.type === 'done' && hasResponseData && Object.keys(fetcher?.data?.error ?? {}).length === 0) {
      onRequestClose();
    }
  }, [fetcher.type, fetcher.data, onRequestClose]);

  const onFormChange = (e: { currentTarget: HTMLFormElement | undefined }) => {
    const formData = new FormData(e.currentTarget);
    const linkValue = formData.get('link');

    if (linkValue && !isValidUrl((linkValue as string) || '')) {
      setError(t('shared.validLink'));
      setEnable(false);
    } else {
      setError('');
      setEnable(formData.get('title') !== title || linkValue !== link);
    }
  };

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('shared.editCreatorsResponseDetails', { name })}</h1>
      <fetcher.Form
        onChange={onFormChange}
        method="post"
        action={routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(grid_id || 'null', topic_id || 'null')}
      >
        <div className="fk-modalPanel -columns editVideoDetailsModal__caption">
          <TextArea
            label={t('common.description')}
            placeholder={t('shared.updateDescriptionResponse')}
            maxLength={100}
            name="title"
            defaultValue={title ?? null}
            data-testid="editModal__textarea__description"
            counter={title ? title.length : '0'}
            rows="2"
            wrapperClassName="fk-panel__span"
          />
          <Input
            label={t('common.link')}
            placeholder={t('shared.updateLinkResponse')}
            maxLength={255}
            defaultValue={link ?? undefined}
            name="link"
            data-testid="editModal__input__link"
            error={error}
            wrapperClassName="fk-panel__span"
          />
        </div>
        <Modal.Actions className="mt2">
          <ButtonSubmit
            name="_action"
            value="updateResponseDetails"
            data-testid="editModal__button__update"
            loading={fetcher.state !== 'idle'}
            disabled={!enable}
          >
            {t('common.save')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="id" value={id} />
        <input type="hidden" name="grid_id" value={grid_id} />
      </fetcher.Form>
    </Modal>
  );
};

export default EditResponsesDetailsModal;
