import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useNavigation, useFetcher } from '@remix-run/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { FlipResponse } from 'types';

type Props = {
  backRoute?: string;
  response: FlipResponse;
  onRequestClose: OnRequestCloseType;
};

// This is a modal that is used to delete a video.
const DeleteMyVideoModal = ({ backRoute, response, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const fetcher = useFetcher<Response>();

  useEffect(() => {
    if (fetcher.type === 'done') {
      const { data } = fetcher;
      if (data && data.ok) {
        onRequestClose(t('deleteMyVideoModal.videoWasDeletedModalClosed'));
      }
      onRequestClose(t('shared.deleteModalClosed'));
    }
  }, [fetcher, fetcher.type, onRequestClose, t]);

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form method="post" action={routes.MY_VIDEOS}>
        <h1 className="fk-modalHeader">{t('deleteMyVideoModal.deleteVideo')}</h1>
        <p className="fk-modalBody">{t('deleteMyVideoModal.confirmDeleteMyVideo')}</p>
        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="deleteMyVideoModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="deleteVideo"
            theme="danger"
            data-testid="deleteMyVideoModal__button__removeOrDelete"
            loading={navigation.state !== 'idle'}
          >
            {t('shared.yesDelete')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="back_url" value={backRoute || ''} />
        <input type="hidden" name="id" value={response.id} />
      </fetcher.Form>
    </Modal>
  );
};

export default DeleteMyVideoModal;
