import {
  Dropdown,
  IcFluentClosedCaptions24Regular,
  IcFluentEdit24Regular,
  IcFluentFlag24Regular,
  IcFluentDelete24Regular,
  IcFluentMoreHorizontal24Regular,
  IcFluentRestore24Regular,
  ModalConsumer,
  IcFluentMixtape24Regular,
  IcFluentArrowDownload24Regular,
  IcFluentImageCopy24Regular,
  IcFluentTextboxAlignBottom24Regular,
  IcFluentPin24Regular,
  IcFluentPin24Filled,
  IcFluentMailInboxArrowRight24Regular,
} from '@flipgrid/flipkit';
import { useFetcher, useLocation, useMatches } from '@remix-run/react';
import { useContext, type KeyboardEvent, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import ActiveStateDropdown from '../Dropdown/ActiveStateDropdown';
import DropdownLink from '../FkWrappers/DropdownLink';
import DeleteResponseModal from '../Modals/DeleteResponseModal';
import DuplicateResponseModal from '../Modals/Duplicate/DuplicateResponseModal';
import EditResponseUsernameGroupModal from '../Modals/EditResponseUsernameGroupModal';
import EditResponsesDetailsModal from '../Modals/EditResponsesDetailsModal';
import AddVideoToMixtapeModal from '../Modals/Mixtape/AddVideoToMixtapeModal';
import NoMixtapeFoundModal from '../Modals/Mixtape/NoMixtapeFoundModal';
import MoveResponseModal from '../Modals/Move/MoveResponseModal';
import ShareButton, { links as shareButtonStyles } from '../Utility/Buttons/ShareButton';
import ReportModal, { links as reportModalStyles } from '~/components/Report/ReportModal';
import endpoints from '~/constants/endpoints';
import resourceRoutes from '~/constants/resourceRoutes';
import globalContext from '~/contexts/globalContext';
import responseListContext from '~/contexts/responseListContext';
import { filenameImg, filenameVideo, isFlagPresent } from '~/helper/helper';
import useGetUser from '~/hooks/useGetUser';

import type { ShowModalType, themesForNonTextVariants } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { GridFlipResponse, Topic } from 'types';

export const links: LinksFunction = () => [...reportModalStyles(), ...shareButtonStyles()];

type Props = {
  backRoute?: string;
  captionsUrl?: string;
  className?: string;
  id?: string;
  isGroupLead: boolean;
  isUsernameGroup: boolean;
  response: GridFlipResponse;
  size?: '26' | '36';
  theme?: themesForNonTextVariants;
  topic: Topic;
  videoMethods?: { pause: () => void } | null;
};

const ResponseActionsDropdown = ({
  backRoute,
  captionsUrl,
  className,
  id,
  isGroupLead,
  isUsernameGroup,
  response,
  size,
  theme = 'overlay',
  topic,
  videoMethods,
}: Props) => {
  const { t } = useTranslation();
  const { mixtapes, mixtapesMetadata, setMixtapes, setMixtapesMetadata } = useContext(responseListContext);
  const location = useLocation();
  const user = useGetUser();
  const [rootData] = useMatches();
  const { env, ua } = rootData.data;
  const fetcher = useFetcher();
  // accounts for username groups
  const isOwnedByUser = !isUsernameGroup && user && user.id === response.user_id;
  const isOwnedByUserInUsernameGroup = isUsernameGroup && user && user.id === response.student_id;

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const pauseVideo = () => {
    if (videoMethods && videoMethods.pause) videoMethods.pause();
  };

  const pinResponse = () => {
    const formData = new FormData();
    formData.append('id', response.id.toString());
    if (response.grid_id) formData.append('grid_id', response.grid_id.toString());
    formData.append('_action', 'updateResponse');

    if (response.featured) {
      formData.append('featured', 'false');
    } else {
      formData.append('featured', 'true');
    }
    fetcher.submit(formData, {
      method: 'put',
      action: `${resourceRoutes.updateState}?manage=true`,
    });
  };

  const videoLinkUrl = `${response.video_url}?filename=${filenameVideo(response)}&download=true`;
  const imageLinkUrl = `${response.image_url}?filename=${filenameImg(response)}&download=true`;

  const modalToDisplay = (showModal: ShowModalType) => {
    if (mixtapes) {
      showModal(AddVideoToMixtapeModal, {
        item: response,
        defaults: {
          mixtapes,
          mixtapesMetadata,
          setMixtapes,
          setMixtapesMetadata,
        },
      });
    } else {
      showModal(NoMixtapeFoundModal);
    }
  };

  return (
    <ModalConsumer>
      {({ showModal }) => {
        return (
          (isOwnedByUser || isGroupLead) && (
          <Dropdown
            button={
              <Dropdown.Button
                id={id}
                aria-controls=""
                aria-label={t('responseActionsDropdown.dropdownButton')}
                className={className}
                data-testid="responseActionsDropdown__button__dropdown"
                icon={<IcFluentMoreHorizontal24Regular />}
                onClick={(e: MouseEvent | KeyboardEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                size={size}
                theme={theme}
              />
            }
          >
            {isDeprecated
              ? null
              : (isOwnedByUser || isGroupLead || isOwnedByUserInUsernameGroup) && (
                  <Dropdown.Item
                    data-testid="responseActionsDropdown__dropdownItem__edit"
                    icon={<IcFluentEdit24Regular />}
                    onSelect={() => {
                      pauseVideo();
                      showModal(
                        isUsernameGroup && isGroupLead ? EditResponseUsernameGroupModal : EditResponsesDetailsModal,
                        { response },
                      );
                    }}
                  >
                    {t('shared.editResponseDetails')}
                  </Dropdown.Item>
                )}
            {isGroupLead && (
              <>
                <ShareButton
                  displayType="dropdown"
                  entity={{
                    item: response,
                    type: 'Response',
                  }}
                  showModal={showModal}
                  aria-label={t('responseActionsDropdown.shareVideoTitle', { title: response.name })}
                />
                {isDeprecated ? null : (
                  <Dropdown.Item
                    icon={response.featured ? <IcFluentPin24Regular /> : <IcFluentPin24Filled />}
                    onSelect={() => pinResponse()}
                    data-testid="responseActionsDropdown__dropdownItem__pinResponse"
                  >
                    {response.featured
                      ? t('responseActionsDropdown.unpinResponse')
                      : t('responseActionsDropdown.pinResponse')}
                  </Dropdown.Item>
                )}
                {isDeprecated ? null : (
                  <Dropdown.Item
                    icon={<IcFluentMailInboxArrowRight24Regular />}
                    onSelect={() => showModal(MoveResponseModal, { topic, response })}
                    data-testid="responseActionsDropdown__dropdownItem__moveResponse"
                  >
                    {t('shared.moveResponse')}
                  </Dropdown.Item>
                )}
                {isDeprecated ? null : (
                  <Dropdown.Item
                    data-testid="responseActionsDropdown__dropdownItem__duplicateResponse"
                    icon={<IcFluentRestore24Regular />}
                    onSelect={() => showModal(DuplicateResponseModal, { response })}
                  >
                    {t('shared.duplicateResponse')}
                  </Dropdown.Item>
                )}
                {!isDeprecated && <ActiveStateDropdown updateStateFetcher={fetcher} item={response} type="Response" />}
              </>
            )}

            {(isOwnedByUser || isGroupLead) && <Dropdown.Divider />}

            {(isOwnedByUser || isGroupLead) && (
              <>
                {isDeprecated
                  ? null
                  : !location.pathname.includes('captions') && (
                      <DropdownLink
                        data-testid="responseActionsDropdown__dropdownItem__editCaptions"
                        icon={<IcFluentClosedCaptions24Regular />}
                        to={captionsUrl || './captions'}
                      >
                        {t('shared.editCaptions')}
                      </DropdownLink>
                    )}
                {/* {response.transcript && !ua.mobile && (
                  <Dropdown.Item
                    data-testid="responseActionsDropdown__dropdownItem__downloadCaptions"
                    icon={<IcFluentTextboxAlignBottom24Regular />}
                    href={
                      isOwnedByUser
                        ? endpoints.RESPONSES_ID_TRANSCRIPT_TXT_FUNC(response.id, env.CLIENT_API_URL)
                        : endpoints.MANAGE_GRIDS_ID_RESPONSES_ID_TRANSCRIPT_TXT(
                            env.CLIENT_API_URL,
                            response.grid_id as number,
                            response.id,
                          )
                    }
                    newTab
                  >
                    {t('common.downloadCaptions')}
                  </Dropdown.Item>
                )} */}
                <Dropdown.Item
                  href={videoLinkUrl}
                  newTab
                  data-testid="responseActionsDropdown__dropdownItem__downloadVideo"
                  icon={<IcFluentArrowDownload24Regular />}
                >
                  {t('common.downloadVideo')}
                </Dropdown.Item>
                <Dropdown.Item
                  href={imageLinkUrl}
                  newTab
                  data-testid="responseActionsDropdown__dropdownItem__downloadCoverImg"
                  icon={<IcFluentImageCopy24Regular />}
                >
                  {t('common.downloadCoverImg')}
                </Dropdown.Item>
              </>
            )}

            {isDeprecated
              ? null
              : isGroupLead && (
                  <Dropdown.Item
                    onSelect={() => modalToDisplay(showModal)}
                    icon={<IcFluentMixtape24Regular />}
                    data-testid="responseActionsDropdown__dropdownItem__addToMixtapes"
                  >
                    {t('shared.addToMixtapes')}
                  </Dropdown.Item>
                )}

            {(isOwnedByUser || isGroupLead || isOwnedByUserInUsernameGroup) && <Dropdown.Divider />}

            {isDeprecated
              ? null
              : (isGroupLead || isOwnedByUser || isOwnedByUserInUsernameGroup) && (
                  <Dropdown.Item
                    data-testid="responseActionsDropdown__dropdownItem__deleteResponse"
                    icon={<IcFluentDelete24Regular />}
                    onSelect={() => showModal(DeleteResponseModal, { response, backRoute })}
                  >
                    {t('shared.deleteResponse')}
                  </Dropdown.Item>
                )}
            {/* WIP - https://dev.azure.com/Flipgrid/FG/_workitems/edit/59557} */}
            {/* There are stages to deprecation, the report functionality remains until July 31st, 2024  */}
            {/* <Dropdown.Item
              data-testid="responseActionsDropdown__dropdownItem__reportVideo"
              icon={<IcFluentFlag24Regular />}
              onSelect={() =>
                showModal(ReportModal, {
                  contentOwnerDisplayName: response.display_name,
                  contentType: 'response',
                  responseId: response.id,
                  gridId: response.grid_id,
                  topicId: response.topic_id,
                })
              }
            >
              {t('shared.reportVideo')}
            </Dropdown.Item> */}
          </Dropdown>
          )
        );
      }}
    </ModalConsumer>
  );
};

export default ResponseActionsDropdown;
