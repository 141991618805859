// Todo: find a smaller library to replace this with, and one we can replace other instances of Combobox with instead of Reach
// such as https://headlessui.com/react/combobox

/* eslint-disable i18next/no-literal-string */
/* eslint-disable react/no-unstable-nested-components */
import { IcFluentDismissCircle24Filled } from '@flipgrid/flipkit';
import { useRef, useId } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { ClientOnly } from 'remix-utils';

import multiSelectStyles from '~/styles/components/Utility/components/MultiSelect.css';

import type { MultiSelectOption } from 'types';

export const links = () => [{ rel: 'stylesheet', href: multiSelectStyles }];

type Props = {
  'aria-describedby'?: string;
  'aria-required'?: string;
  /** Classes for multi-select */
  className?: string;
  disabled?: boolean;
  displayLabel?: string;
  /** onChange for select */
  onChange?: (arg: MultiSelectOption[]) => void;
  placeholder?: string;
  /** id for tests */
  'data-testid': string;
  /** The error message itself */
  error?: boolean | string;
  /** Classes for error */
  errorClassName?: string;
  /** Sets the form label */
  label?: string;
  /** The options in the multiSelect in the format [{value="", label=""}, {value="", label=""}] */
  options: MultiSelectOption[];
  onClear?: (arg: MultiSelectOption[]) => void;
  /** The selected options */
  value?: MultiSelectOption[];
  /** Classes for multi-select wrapper */
  wrapperClassName?: string;
};

const MultiSelect = ({
  'aria-describedby': ariaDescribedBy = '',
  'aria-required': ariaRequired,
  className = '',
  disabled = false,
  displayLabel,
  error,
  errorClassName,
  label,
  onChange,
  placeholder = '',
  value,
  wrapperClassName = '',
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const controlRef = useRef<Element>(null);
  const selectRef = useRef<TSFix>(null);
  const valueContainerRef = useRef<TSFix>(null);
  const idValue = useId();
  const multiLabelId = useId();

  const getClasses = () => {
    const classList = ['fk-multiSelect'];
    if (className) classList.push(className);
    if (error) classList.push('-error');

    return classList.join(' ');
  };

  function getWrapperClasses() {
    const classList = ['fk-field__wrapper'];
    if (wrapperClassName) classList.push(wrapperClassName);

    return classList.join(' ');
  }

  function getErrorClasses() {
    const classList = ['fk-error'];
    if (errorClassName) classList.push(errorClassName);

    return classList.join(' ');
  }

  const IndicatorsContainer = (props: TSFix) => {
    let needsTruncation;
    if (valueContainerRef.current && controlRef.current) {
      // - 96 accounts for spacing around existing markup
      needsTruncation = valueContainerRef.current.clientWidth > controlRef.current.clientWidth - 96;
    }

    return (
      <components.IndicatorsContainer {...props}>
        {needsTruncation &&
        <div className="fk-reactSelect__selectedCount-indicator">
            <span className="fk-reactSelect__selectedCount-ellipsis">&#8230;</span>
            {value && value.length > 1 && ` (${value.length})`}
          </div>}

        {props.children}
      </components.IndicatorsContainer>);

  };

  const ValueContainer = (props: TSFix) =>
  <components.ValueContainer {...props}>
      {/* ValueContainer doesn't support innerRef, so wrap children with a ref */}
      <span id={multiLabelId} ref={valueContainerRef}>
        {props.children}
      </span>
    </components.ValueContainer>;


  const ClearIndicator = (props: TSFix) => {
    const {
      children = <IcFluentDismissCircle24Filled className="fk-reactSelect__clear-indicator" />,
      getStyles,
      innerProps: { ref, ...restInnerProps }
    } = props;
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <div>{children}</div>
      </div>);

  };

  const Control = (props: TSFix) => <components.Control {...props} innerRef={controlRef} />;

  return (
    <ClientOnly>
      {() =>
      <div className={getWrapperClasses()}>
          {displayLabel &&
        <label className={'fk-label' + (error ? ' -error' : '')} htmlFor={`react-select-${idValue}-input`}>
              {ariaRequired ? '* ' : null}
              {displayLabel}
            </label>}

          <Select
          ref={selectRef}
          classNamePrefix="fk-reactSelect"
          className={getClasses()}
          placeholder={placeholder || label}
          instanceId={idValue}
          noOptionsMessage={() => t('shared.noResultsFound')}
          hideSelectedOptions={false}
          name={label}
          value={value}
          maxMenuHeight={250}
          isDisabled={disabled}
          isMulti
          tabSelectsValue={false}
          onChange={(event) => {
            // @ts-expect-error
            if (onChange) onChange(event);
            if (selectRef && selectRef.current) {
              setTimeout(() => {
                selectRef.current.focus();
              }, 500);
            }
          }}
          aria-labelledby={`${error && typeof error !== 'boolean' ? idValue + '-error ' : ''} ${ariaDescribedBy} ${
          value && value.length > 0 ? multiLabelId : ''
          }`}
          components={{
            Control,
            IndicatorsContainer,
            ValueContainer,
            ClearIndicator
          }}
          {...rest} />

          {error && typeof error !== 'boolean' ? // allows for unique error handling
        <div id={idValue + '-error'} className={getErrorClasses()} aria-live="polite">
              {error}
            </div> :
        null}
        </div>}

    </ClientOnly>);

};

export default MultiSelect;