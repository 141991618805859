import { IcFluentChevronRight24Regular, Toggle } from '@flipgrid/flipkit';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useMenuNavigation from '../../hooks/useMenuNavigation';

type Props = {
  closeMenu: () => void;
  hasReportAvailable: boolean;
  looped?: boolean;
  playbackRate: number;
  setMenu: (argument: string) => void;
  toggleLooped?: () => void;
};

const HomePanel = ({ closeMenu, hasReportAvailable, looped, playbackRate, setMenu, toggleLooped }: Props) => {
  const { t } = useTranslation();
  const panel = useRef<HTMLDivElement>(null);
  const interactiveElements = 'input, button';

  useEffect(() => {
    if (!panel.current) return;
    const active = panel.current.querySelector(interactiveElements) as HTMLElement;
    if (active && active.focus) active.focus();
  }, []);

  useMenuNavigation(panel, { escape: closeMenu, forward: setMenu }, interactiveElements);

  const speed = playbackRate.toFixed(2);

  return (
    <div className="videoPlayerUtility__panel homePanel" ref={panel}>
      {toggleLooped && (
        <Toggle
          className="homePanel__autoplay"
          checked={!!looped}
          label={t('common.autoplay')}
          onChange={toggleLooped}
          aria-label={t('homePanel.toggleVideoAutoplay')}
          size="small"
        />
      )}
      <button
        type="button"
        className="homePanel__playback videoPlayerUtility__panelButton"
        aria-label={t('homePanel.openPlaybackSpeedMenu')}
        data-next-menu="PLAYBACK"
        onClick={() => {
          setMenu('PLAYBACK');
        }}
      >
        <>
          {playbackRate === 1 ? (
            <Trans i18nKey="homePanel.playbackSpeedNormal">
              Playback Speed
              <span>(Normal)</span>
            </Trans>
          ) : (
            <Trans i18nKey="homePanel.playbackSpeedNum">
              Playback Speed
              <span>
                <>({{ speed }})</>
              </span>
            </Trans>
          )}
          <IcFluentChevronRight24Regular className="homePanel__caret" />
        </>
      </button>
      {hasReportAvailable && (
        <button
          type="button"
          className="homePanel__report videoPlayerUtility__panelButton"
          aria-label={t('homePanel.openReportVideoMenu')}
          data-next-menu="REPORT"
          onClick={() => {
            setMenu('REPORT');
          }}
        >
          <>
            {t('common.report')}
            <IcFluentChevronRight24Regular className="homePanel__caret" />
          </>
        </button>
      )}
    </div>
  );
};

export default HomePanel;
