import { Anchor, Button } from '@flipgrid/flipkit';
import { useTranslation, Trans } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import Sparkles from '~/svg/Sparkles';

type Props = {
  handleRetry: () => void;
};

const ErrorScreen = ({ handleRetry }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="errorScreen">
      <Sparkles className="errorScreen__sparkles" />
      <h1 className="errorScreen__header">{t('errorScreen.preparingVideo')}</h1>
      <p className="errorScreen__text">
        <Trans i18nKey="errorScreen.ifYouContinue">
          If you continue to see this message after a few minutes,{' '}
          <Anchor theme="copy" to={externalLinks.VideoPlayback} data-testid="errorScreen__anchor__learnMore" newTab>
            go here to learn more
          </Anchor>
          .
        </Trans>
      </p>
      <Button theme="primary" data-testid="errorScreen__button__refreshVideo" onClick={handleRetry}>
        {t('errorScreen.refreshVideo')}
      </Button>
    </div>
  );
};

export default ErrorScreen;
