import { Modal } from '@flipgrid/flipkit';
import { useTranslation, Trans } from 'react-i18next';

import Link from '~/components/FkWrappers/Link';
import TopicImageCircle, { links as topicImageCircleStyles } from '~/components/MyVideos/TopicImageCircle';
import routes from '~/constants/routes';
import successPostToTopicModalStyles from '~/styles/components/Modals/MyVideos/SuccessPostToTopicModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Topic } from 'types';

export const links = () => [...topicImageCircleStyles(), { rel: 'stylesheet', href: successPostToTopicModalStyles }];

type Props = {
  onRequestClose: OnRequestCloseType;
  topic: Topic;
  responseImage: string;
};
const SuccessPostToTopicModal = ({ onRequestClose, topic, responseImage }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onRequestClose}>
      <div className="successPostToTopicModal__wrapper">
        {responseImage &&
        <img alt="" className="successPostToTopicModal__responseImage" src={`${responseImage}?size=large`} />}

        <h1 className="fk-modalHeader">{t('common.successExclamation')}</h1>
        <p className="fk-modalBody fk-mb0">
          {topic.moderated ?
          t('successPostToTopicModal.postedToModeratedTopic') :

          <Trans i18nKey="successPostToTopicModal.postedToTopicSuccessDesc">
              You've shared your video with <b>{{ groupName: topic.grid_name }}</b> by posting in:
            </Trans>}

        </p>
        <div className="successPostToTopicModal__posted">
          <TopicImageCircle topic={topic} />
          <h2 className="successPostToTopicModal__text fk-m0 fk-h3">{topic.title}</h2>
        </div>
      </div>

      <Modal.Actions className="mt2">
        <Link
          to={routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(topic.grid_id, topic.id)}
          onClick={() => {
            onRequestClose();
          }}
          data-testid="successPostToTopicModal__link__goToTopic"
          variant="block">

          {t('shared.goToTopic')}
        </Link>
      </Modal.Actions>
    </Modal>);

};

export default SuccessPostToTopicModal;