import { Combobox as FlipKitCombobox } from '@flipgrid/flipkit';
import { useContext, useState } from 'react';

import GlobalContext from '~/contexts/globalContext';

import type { ReactNode } from 'react';

type ComboboxProps = {
  children: ReactNode;
  disabled?: boolean;
  error?: string;
  floatingLabel?: boolean;
  id?: string;
  label: string;
  labelClassName?: string;
  name: string;
  onChange?: (e: TSFix) => void;
  onSelect?: (value: string) => void;
  placeholder?: string;
  selectedValue?: string;
  autocomplete?: string;
};

const Combobox = ({
  children,
  onSelect,
  id,
  label,
  labelClassName,
  name,
  selectedValue = '',
  autocomplete = 'on',
  ...rest
}: ComboboxProps) => {
  const globalContext = useContext(GlobalContext);
  const [selected, setSelected] = useState(selectedValue);

  return (
    <FlipKitCombobox
      {...rest}
      id={id}
      label={label}
      // @ts-ignore
      labelClassName={labelClassName}
      name={name}
      announceLiveMessage={globalContext.announceLiveMessage}
      selectedValue={selected}
      onSelect={(val: string) => {
        if (onSelect) onSelect(val);
        setSelected(val);
      }}
      autoComplete={autocomplete}
    >
      {children}
    </FlipKitCombobox>
  );
};

Combobox.Option = FlipKitCombobox.Option;
Combobox.OptionText = FlipKitCombobox.OptionText;

export default Combobox;
