import { useEventListener } from '@flipgrid/flipkit';
import { useCallback } from 'react';

import type { RefObject } from 'react';

const INTERACTIVE = 'button, a';

// useMenuNavigation is a custom hook to override browser default keyboard navigation
// to implement extendable keyboard menu navigation handling and a focus trapping.
//
// It takes a React ref to the parent element, an escape key handler, and a query string
// that represents the set of focusable elements that are children of the parent element
// as arguments, and sets up a keydown event handler that works with tabs and arrow keys.
export default function useMenuNavigation(
  elementRef: RefObject<HTMLElement>,
  handlers: {
    escape?: () => void;
    forward?: (argument: string) => void;
    backward?: () => void;
  },
  interactive = INTERACTIVE,
) {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 27 && handlers.escape) {
        // ESC
        e.preventDefault();
        e.stopPropagation();
        handlers.escape();
      } else if ((e.keyCode === 9 && !e.shiftKey) || e.keyCode === 40) {
        // Non-shifted Tab || Down Arrow
        // Get the elements that are in the menu and focus the next one. Loop if at the last one.
        e.preventDefault();
        const elements = ((elementRef.current && [...elementRef.current.querySelectorAll(interactive)]) ||
          []) as HTMLElement[];
        const index = (elements.findIndex(element => element === document.activeElement) + 1) % elements.length;
        // @ts-ignore
        if (elements[index] && elements[index].focus) elements[index].focus();
      } else if ((e.keyCode === 9 && e.shiftKey) || e.keyCode === 38) {
        // Shifted Tab || Up Arrow
        // Get the elements that are in the menu and focus the previous one. Loop if at the first one.
        e.preventDefault();
        const elements = ((elementRef.current && [...elementRef.current.querySelectorAll(interactive)]) ||
          []) as HTMLElement[];
        const oldIndex = elements.findIndex(element => element === document.activeElement);
        const index = (oldIndex <= 0 ? elements.length : oldIndex) - 1;
        // @ts-ignore
        if (elements[index] && elements[index].focus) elements[index].focus();
      } else if (e.keyCode === 37 && handlers.backward) {
        // Left arrow
        // There's really only one way to go when going backwards,
        // so if a handler was provided, we call it.
        e.preventDefault();
        e.stopPropagation();
        handlers.backward();
      } else if (e.keyCode === 39 && handlers.forward) {
        // Right arrow
        // When going forwards, there could be multiple menu options. If there's an active element
        // that specifies that it can navigate forward via data-next-menu, we navigate to that menu.
        e.preventDefault();
        e.stopPropagation();
        // @ts-ignore
        const next = document.activeElement && (document.activeElement as HTMLElement).dataset.nextMenu;
        if (next) handlers.forward(next);
      }
    },
    [elementRef, interactive, handlers],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEventListener('keydown', handleKeyDown, true, elementRef.current as any);
}
