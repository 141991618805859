import { Anchor, Button, IcFluentChevronLeft24Regular } from '@flipgrid/flipkit';
import { useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import useMenuNavigation from '../../hooks/useMenuNavigation';
import externalLinks from '~/constants/externalLinks';

type Props = {
  closeMenu: () => void;
  report: () => void;
  setMenu: (arg: string) => void;
};

const ReportPanel = ({ closeMenu, report, setMenu }: Props) => {
  const { t } = useTranslation();
  const panel = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!panel.current) return;
    const active = panel.current.querySelector('button');
    if (active && active.focus) active.focus();
  }, []);

  useMenuNavigation(panel, { escape: closeMenu, backward: () => setMenu('HOME') });

  return (
    <div className="videoPlayerUtility__panel reportPanel" ref={panel}>
      <Button
        type="button"
        className="reportPanel__back"
        aria-label={t('reportPanel.backToMainMenu')}
        onClick={() => {
          setMenu('HOME');
        }}
        theme="transparent"
        icon={<IcFluentChevronLeft24Regular />}
        data-testid="reportPanel__button__back"
      >
        {t('common.report')}
      </Button>

      <p className="reportPanel__copy">
        <>
          <Trans i18nKey="reportPanel.pleaseReportVideo">
            Please report this video if you find that it contains inappropriate content or may be in violation of Flip's
            <Anchor to={externalLinks.TermsOfUse} newTab data-testid="reportPanel__anchor__termsOfUse" theme="legal">
              Terms of Use
            </Anchor>
            .
          </Trans>
          <br />
          <br />
          {t('reportPanel.reportingThisVideo')}
        </>
      </p>
      <div className="reportPanel__buttonContainer">
        <Button
          type="button"
          aria-label={t('reportPanel.cancelReporting')}
          onClick={() => {
            setMenu('HOME');
          }}
          theme="secondary"
          data-testid="reportPanel__button__cancel"
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="button"
          aria-label={t('shared.reportVideo')}
          onClick={report}
          theme="danger"
          data-testid="reportPanel__button__report"
        >
          {t('common.report')}
        </Button>
      </div>
    </div>
  );
};

export default ReportPanel;
