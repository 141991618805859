import {
  Button,
  IcFluentArrowUp24Regular,
  IcFluentChevronDown24Regular,
  IcFluentChevronUp24Regular,
  IcFluentComment24Filled,
  IcFluentDismiss24Regular,
  IcFluentHeart24Filled,
  Loader,
  PartyingFace,
  useGeneratedPrefixedId } from
'@flipgrid/flipkit';
import { useFetcher, useMatches, useNavigate, useSearchParams } from '@remix-run/react';
import classNames from 'classnames';
import { debounce, has, throttle } from 'lodash-es';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ClosedCaptions from './ClosedCaptions';
import ErrorScreen from './ErrorScreen';
import VideoPlayerControls from './VideoPlayerControls/VideoPlayerControls';
import VideoReactionDialog from './VideoReactionDialog';
import { localStorageKeys } from './constants/constants';
import { getStorageItem, setStorageItem } from './helper/storage';
import Link from '../FkWrappers/Link';
import MyVideosActionsDropdown, { links as myVideosActionsDropdownStyles } from '../MyVideos/MyVideosActionsDropdown';
import ResponseActionsDropdown, { links as responseActionsDropdownStyles } from '../Responses/ResponseActionsDropdown';
import TopicCard, { links as topicCardStyles } from '../Utility/Buttons/TopicCard';
import ProfilePicture, { links as profilePictureStyles } from '../Utility/components/ProfilePicture';
import HandleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import { reactionEventName } from '~/constants/videoReactionConstants';
import GlobalContext from '~/contexts/globalContext';
import mixtapeListContext from '~/contexts/mixtapeListContext';
import PlayerContext from '~/contexts/playerContext';
import responseListContext from '~/contexts/responseListContext';
import { pauseAllOtherVideos } from '~/helper/component/videoPlayerUtilities';
import { timeAgo } from '~/helper/dateHelpers';
import { isFlagPresent, logEvent } from '~/helper/helper';
import { clientFetch } from '~/helper/helper.client';
import { handleError } from '~/helper/imgOnError';
import useGetUser from '~/hooks/useGetUser';
import videoPlayerStyles from '~/styles/components/WebPlayer/index.css';

import type { SyntheticEvent } from 'react';
import type {
  FlipResponse,
  GridFlipResponse,
  LikeResponseType,
  MixtapeResponse,
  RouteType,
  RouteTyping,
  Topic,
  VideoComment,
  VideoFocus } from
'types';

export const links = () => [
...topicCardStyles(),
...profilePictureStyles(),
...myVideosActionsDropdownStyles(),
...responseActionsDropdownStyles(),
{ rel: 'stylesheet', href: videoPlayerStyles }];


export type Likes = {allowLikes?: false;} | {allowLikes: true;responseId: string;groupId: string | number;};

export type VideoPlayerProps = {
  allowStorage?: boolean;
  'aria-hidden'?: 'true' | 'false';
  autoPlay?: boolean;
  backRoute?: string;
  baseUrl?: RouteType;
  captionsSrc?: string;
  children?: TSFix;
  closeButton?: boolean;
  entity?:
  {
    commentsUrl?: never;
    isGroupLead?: never;
    item: VideoComment;
    topic: Topic;
    type: 'Comment';
  } |
  {
    commentsUrl?: never;
    isGroupLead?: never;
    item: MixtapeResponse;
    topic?: never;
    type: 'Mixtape';
  } |
  {
    commentsUrl: string;
    isGroupLead: boolean;
    item: GridFlipResponse;
    topic: Topic;
    type: 'Response';
  } |
  {
    commentsUrl: string;
    isGroupLead?: never;
    item: FlipResponse;
    topic?: never;
    type: 'StandaloneResponse';
  } |
  {
    commentsUrl?: never;
    isGroupLead?: never;
    item: VideoFocus;
    topic: Topic;
    type: 'TopicFocus';
  };
  initialDuration?: number | null;
  likes?: Likes;
  loop?: boolean;
  markAsViewed: () => void;
  messaging?: boolean;
  nextVideo?: {
    commentsUrl: string;
    onClick: () => void;
    url: string;
  };
  onClose?: () => void;
  onControlsShown?: () => void;
  onControlsHidden?: () => void;
  onError?: (err: string | Error, { errorVector }?: {errorVector: string;}) => void;
  onFullscreenEnter?: () => void;
  onFullscreenExit?: () => void;
  onPause?: () => void;
  onPlay?: () => void;
  onReady?: () => void;
  onEnded?: () => void;
  playNext?: boolean;
  poster?: string;
  prevVideo?: {
    commentsUrl: string;
    onClick: () => void;
    url: string;
  };
  src: string;
  style?: object;
  videoPlayerId?: string;
};

// Limit the number of times a video will automatically loop playback in a given
// session to stop the spamming of views endpoint/annoying short video loop audio.
const MAX_VIDEO_LOOPS_IN_SESSION = 10;

const VideoPlayer = ({
  allowStorage = true,
  'aria-hidden': ariaHidden,
  autoPlay = false,
  baseUrl,
  backRoute,
  captionsSrc,
  closeButton,
  children,
  entity,
  initialDuration = null,
  likes = { allowLikes: false },
  loop,
  markAsViewed,
  messaging,
  nextVideo,
  onClose,
  onControlsShown,
  onControlsHidden,
  onError,
  onFullscreenEnter,
  onFullscreenExit,
  onPause,
  onPlay,
  onReady,
  onEnded,
  playNext,
  poster,
  prevVideo,
  src,
  style = {},
  videoPlayerId
}: VideoPlayerProps) => {
  const { announceLiveMessage } = useContext(GlobalContext);
  const { currentTime, setCurrentTime, setVideoMethods } = useContext(PlayerContext);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [activeCue, setActiveCue] = useState<TextTrackCue | null>(null);
  const [canControlControlsVisibility, setCanControlControlsVisibility] = useState(true);
  const [closedCaptions, setClosedCaptions] = useState(false);
  const [duration, setDuration] = useState(initialDuration);
  const [error, setError] = useState(!src);
  const hasViewedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(!!src);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(
    allowStorage ? getStorageItem(localStorageKeys.playbackRate) || 1 : 1
  );
  const [showControls, setShowControls] = useState(true);
  const [track, setTrack] = useState<TextTrack | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const backToTopLinkRef = useRef<HTMLLinkElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const generatedId = useGeneratedPrefixedId('videoPlayer');
  const id = videoPlayerId ?? generatedId;
  const nextVideoRef = useRef(nextVideo);
  const lastVideo = useRef(false);
  const shouldPlayNext = useRef(playNext);
  const navigate = useNavigate();
  const { featureFlags } = useContext(GlobalContext);
  const isVideoReactionEnabled = isFlagPresent(featureFlags, 'web-videoReaction');
  const isUsernameGroup = entity?.topic?.access_control === 'student';
  const [hasLiked, setHasLiked] = useState(entity?.type === 'Response' ? !!entity.item.liked : false);
  const [showBumper, setShowBumper] = useState(false);
  const video = useRef<HTMLVideoElement>(null);
  // const trackRef = useRef<HTMLTrackElement>(null);
  const videoContainer = useRef<HTMLDivElement>(null);
  const playButton = useRef<HTMLButtonElement>();
  const hasVideoPlayerBeenReady = useRef<boolean>(false);
  const videoLoopsInCurrentSession = useRef<number>(0);
  const portalTargetIdentifier = useGeneratedPrefixedId('settings-menu-portal-target');
  const likeFetcher = useFetcher<LikeResponseType>();
  const matches = useMatches();
  const ua = (matches.find((m) => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const lastMatch = matches[matches.length - 1];
  const commentsOpen = useRef(lastMatch.handle?.id === HandleIds.Comments);
  const commentsData =
  entity?.type === 'Comment' ? (
  matches.find((m) => m.handle?.id === HandleIds.Comments)?.data as RouteTyping<'Comments'>) :
  undefined;
  const commentUsers = commentsData?.comments?.included?.users;
  const user = useGetUser();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const [playbackVideoUrl, setPlaybackVideoUrl] = useState<string>(src);
  const playsInline = ua?.mobile;
  const mixtapeContext = useContext(mixtapeListContext);
  const responseContext = useContext(responseListContext);
  const localVideoListContext = entity ? entity.type === 'Mixtape' ? mixtapeContext : responseContext : undefined;
  const setResponseVideos = responseContext?.setVideos;
  const lastVideoId =
  localVideoListContext?.videos && localVideoListContext.videos[localVideoListContext.videos.length - 1]?.id;
  const entityClassNameKey = useMemo(() => {
    if (entity?.type === 'Comment') {
      return `comment-${entity.item.id.toString()}`;
    }
    return '';
  }, [entity]);

  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');
  likes.allowLikes = isDeprecated ? false : likes.allowLikes;

  useEffect(() => {
    if (shouldPlayNext) shouldPlayNext.current = playNext;
  }, [playNext]);

  useEffect(() => {
    setupListeners();
    if (messaging) initPostMessageHandler();
    if (autoPlay) handleHideControls();
    hoistMethods();
    setTimeout(() => {
      setupUserPreferences();
    }, 0);

    return () => {
      handleHideControls.cancel();
      if (messaging) cleanupPostMessageHandler();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackVideoUrl]); // Watch for video_url changes

  useEffect(() => {
    // avoid stale closure
    nextVideoRef.current = nextVideo;
  }, [nextVideo]);

  useEffect(() => {
    if (entity?.type !== 'TopicFocus') {
      const isLast = lastVideoId === entity?.item.id;
      lastVideo.current = isLast;
      if (!isLast && showBumper) setShowBumper(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, lastVideoId]);

  useEffect(() => {
    commentsOpen.current = lastMatch.handle?.id === HandleIds.Comments;
  }, [lastMatch.handle?.id]);

  useEffect(() => {
    setHasLiked(entity?.type === 'Response' ? !!entity.item.liked : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity?.item]);

  useEffect(() => {
    if (likeFetcher.type === 'done' && likeFetcher.data) {
      const videoWasLiked = likeFetcher.data.intent === 'like';

      // Update call was successful
      if (likeFetcher.data.ok) {
        announceLiveMessage(videoWasLiked ? t('like.success') : t('like.removalSuccess'));
      } else {
        // Unset optimistic update on failure
        if (setResponseVideos && (entity?.type === 'Response' || entity?.type === 'Comment')) {
          setResponseVideos((prev) => {
            if (prev) {
              const likedVideo = prev.findIndex((response) => response.id === entity?.item?.id);
              if (likedVideo > -1) {
                const offset = !videoWasLiked ? 1 : -1;
                prev[likedVideo].liked = !videoWasLiked;
                prev[likedVideo].like_count += offset;
              }
            }
            return prev;
          });
        }

        setHasLiked(!videoWasLiked);

        // Call failed with unknown error
        announceLiveMessage('like.problemUpdatingLike');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likeFetcher, setResponseVideos, t]); // announceLiveMessage as a dependency causes an infinite loop

  useEffect(() => {
    setPlaybackVideoUrl(src); // Added to update playbackVideoUrl whenever user goes to next or prev video.
  }, [src]);

  useEffect(() => {
    if (error) {
      const url = new URL(playbackVideoUrl);
      const params = new URLSearchParams(url.search);
      const hasOriginalAssetURL = params.has('original') && params.get('original') === 'true';
      if (!hasOriginalAssetURL) {
        const ogAssetSrc = `${src}?original=true'`;
        setPlaybackVideoUrl(ogAssetSrc);
        setError(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [error, src, playbackVideoUrl]);

  const setupListeners = () => {
    const videoPlayer = video.current;
    videoPlayer?.addEventListener('play', () => {
      pauseAllOtherVideos(id);
      setIsPlaying(true);
      if (onPlay) onPlay();
      sendPostMessage('fg-wvp-onPlay');
      sendPostMessage('fg-wvp-onStateChange', { state: 'playing' });
      if (!hasViewedRef.current) {
        hasViewedRef.current = true;
        videoLoopsInCurrentSession.current += 1;
        markAsViewed();
      }
      setIsLoading(false);
    });
    videoPlayer?.addEventListener('pause', () => {
      setIsPlaying(false);
      if (onPause) onPause();
      const { currentTime: videoPlayerCurrentTime } = videoPlayer;
      sendPostMessage('fg-wvp-onPause', { videoPlayerCurrentTime });
      sendPostMessage('fg-wvp-onStateChange', { state: 'paused' });
    });
    videoPlayer?.addEventListener('loadedmetadata', (e) => {
      if (!video.current) return;
      let currentDuration = -1;
      const target = (e.target as HTMLVideoElement);
      if (target && typeof target.duration === 'number' && target.duration > 0 && target.duration !== Infinity) {
        currentDuration = Math.floor(target.duration);
      }
      if (currentDuration > -1) {
        setDuration(currentDuration);
      }
      setIsLoading(false);
    });
    videoPlayer?.addEventListener('canplay', () => {
      if (onReady) onReady();
      if (!hasVideoPlayerBeenReady.current) {
        hasVideoPlayerBeenReady.current = true;
        sendPostMessage('fg-wvp-onInitialReady');
      }
      sendPostMessage('fg-wvp-onReady');
      sendPostMessage('fg-wvp-onStateChange', { state: 'ready' });
    });
    videoPlayer?.addEventListener('seeked', () => {
      // Chrome bug - If you try to drag handle beyond 0, currentTime is not available, so return.
      if (!video.current) return;
      const { currentTime: videoPlayerCurrentTime } = videoPlayer;
      sendPostMessage('fg-wvp-onSeek', { videoPlayerCurrentTime });
      sendPostMessage('fg-wvp-onStateChange', { state: 'seeked' });
    });
    videoPlayer?.addEventListener('timeupdate', (e) => {
      if (!video.current) return;
      const localActiveCue = loadActiveCue();
      const currentEvent = (e.target as TSFix);
      setActiveCue(localActiveCue);
      setCurrentTime(currentEvent.currentTime);
      sendPostMessage('fg-wvp-onTimeUpdate', { currentTime: currentEvent.currentTime });
    });
    videoPlayer?.addEventListener('ended', () => {
      if (!video.current) return;
      hasViewedRef.current = false; // reset on ended so if they play again, we'll count another view
      const { currentTime: videoPlayerCurrentTime } = videoPlayer;
      sendPostMessage('fg-wvp-onEnded', { videoPlayerCurrentTime });
      sendPostMessage('fg-wvp-onStateChange', { state: 'ended' });
      if (onEnded) onEnded();
      if (shouldPlayNext.current && entity?.type !== 'TopicFocus') {
        const url = commentsOpen.current ? nextVideoRef?.current?.commentsUrl : nextVideoRef?.current?.url;
        if (!lastVideo.current || entity?.type === 'Mixtape') {
          setShowBumper(false);
          nextVideoRef?.current?.onClick();
          if (url) navigate(url);
        } else {
          setShowBumper(true);
        }
      }
      if (loop && videoLoopsInCurrentSession.current < MAX_VIDEO_LOOPS_IN_SESSION) {
        videoPlayer.currentTime = 0;
        videoPlayer.play();
      }
    });
    videoPlayer?.addEventListener('error', (e) => {
      if (onError && has(e, 'target.error')) {
        const localError = (e.target as TSFix); // the types here seem wrong.
        onError(localError.error);
        sendPostMessage('fg-wvp-onError', { error: localError.error });
        sendPostMessage('fg-wvp-onStateChange', { state: 'error' });
      }
      setError(true);
      setIsLoading(false);
    });
    videoPlayer?.addEventListener('waiting', () => {
      sendPostMessage('fg-wvp-onBuffering');
      sendPostMessage('fg-wvp-onStateChange', { state: 'buffering' });
    });
    videoPlayer?.addEventListener('playing', () => {
      if (video?.current?.playbackRate) {
        video.current.playbackRate = playbackRate;
      }
    });
    document.addEventListener('fullscreenchange', () => toggleFullscreenState(), false);
    document.addEventListener('mozfullscreenchange', () => toggleFullscreenState(), false);
    document.addEventListener('webkitfullscreenchange', () => toggleFullscreenState(), false);
    document.addEventListener('msfullscreenchange', () => toggleFullscreenState(), false);
    if (videoPlayer?.textTracks && videoPlayer.textTracks.length) {
      setTrack(videoPlayer.textTracks[0]);
    }
  };

  const handlePostMessage = (event: TSFix) => {
    if (!messaging) return;

    try {
      const { name, data } = event.data;
      if (!name || name.indexOf('fg-wvp') === -1) return;

      switch (name) {
        case 'fg-wvp-seek':
          seek(data.time);
          break;
        case 'fg-wvp-play':
          play();
          break;
        case 'fg-wvp-pause':
          pause();
          break;
        case 'fg-wvp-stop':
          seek(0);
          pause();
          break;
        case 'fg-wvp-showControls':
          forceShowControls();
          break;
        case 'fg-wvp-hideControls':
          forceHideControls();
          break;
        case 'fg-wvp-setVolume':
          setVolume(data.volume);
          break;
        case 'fg-wvp-mute':
          setIsMuted(data.mute);
          sendPostMessage('fg-wvp-onMute', { muted: data.mute });
          break;
        case 'fg-wvp-enterFullscreen':
        case 'fg-wvp-exitFullscreen':
          handleToggleFullscreen();
          break;
        case 'fg-wvp-getVolume':
          if (video.current) {
            sendPostMessage('fg-wvp-getVolumeResult', { volume: video.current.volume });
          }
          break;
        case 'fg-wvp-getDuration':
          if (video.current) {
            sendPostMessage('fg-wvp-getDurationResult', { duration });
          }
          break;
        case 'fg-wvp-getCurrentTime':
          if (video.current) {
            sendPostMessage('fg-wvp-getCurrentTimeResult', { currentTime: video.current.currentTime });
          }
          break;
        case 'fg-wvp-getMute':
          if (video.current) {
            sendPostMessage('fg-wvp-getMuteResult', { isMuted: video.current.muted });
          }
          break;
        default:
          break;
      }
    } catch (ex) {
      if (onError) onError((ex as Error), { errorVector: 'post-messaging' });
    }
  };

  const setupUserPreferences = () => {
    if (!allowStorage) return;

    // Get user preferences for closed captions. If they don't have one, set a default of false.
    const storedClosedCaptions = getStorageItem(localStorageKeys.closedCaptions);
    if (storedClosedCaptions === null || storedClosedCaptions === undefined)
    setStorageItem(localStorageKeys.closedCaptions, false);else
    setClosedCaptions(storedClosedCaptions);

    // Get user preferences playback rate. If they don't have one, set a default of 1.
    const localPlaybackRate = getStorageItem(localStorageKeys.playbackRate);
    if (localPlaybackRate === null || localPlaybackRate === undefined) setStorageItem(localStorageKeys.playbackRate, 1);else
    {
      setPlaybackRate(localPlaybackRate);
      if (video.current) video.current.playbackRate = localPlaybackRate;
    }
  };

  const initPostMessageHandler = () => {
    if (typeof window === 'undefined') return;

    window.addEventListener('message', handlePostMessage);
  };

  const loadActiveCue = () => {
    if (!video.current) return null;

    let localActiveCue = null;
    const currentTrack = video.current.textTracks && video.current.textTracks.length && video.current.textTracks[0];

    if (currentTrack && currentTrack.activeCues && currentTrack.activeCues.length) {
      [localActiveCue] = currentTrack.activeCues;
    }

    return localActiveCue;
  };

  const toggleFullscreenState = () => {
    if (document.fullscreen) {
      setIsFullscreen(true);
      if (onFullscreenEnter) onFullscreenEnter();
    } else {
      setIsFullscreen(false);
      if (onFullscreenExit) onFullscreenExit();
    }
  };

  const sendPostMessage = (name: string, data?: object) => {
    if (!messaging || typeof window === 'undefined') return;

    const target = window && window.parent || window;
    if (!target) return;

    const message: TSFix = { name, sourceName: window.name };
    if (data) message.data = data;

    target.postMessage(message, '*');
  };

  const seek = (time: number) => {
    if (video.current) video.current.currentTime = time;
  };

  const play = () => {
    if (!video || !video.current) return;
    video.current.play();
  };

  const pause = () => {
    if (!video || !video.current) return;
    video.current.pause();
  };

  const forceShowControls = () => {
    setCanControlControlsVisibility(false);
    setShowControls(true);
  };

  const forceHideControls = () => {
    setCanControlControlsVisibility(false);
    setShowControls(false);
  };

  const setVolume = (value: number) => {
    let volume = value;
    if (volume < 0) volume = 0;
    if (volume > 1) volume = 1;

    if (video.current) video.current.volume = volume;
  };

  const handleToggleFullscreen = () => {
    const documentRef: TSFix = document;
    // If the video player is in fullscreen, attempt to exit it
    if (documentRef.fullscreenElement || documentRef.webkitIsFullScreen) {
      let exitPromise = null;
      if (document.exitFullscreen) exitPromise = document.exitFullscreen();else
      if (documentRef.mozCancelFullScreen) exitPromise = documentRef.mozCancelFullScreen();else
      if (documentRef.webkitExitFullscreen) exitPromise = documentRef.webkitExitFullscreen();else
      if (documentRef.msExitFullscreen) exitPromise = documentRef.msExitFullscreen();
      if (exitPromise) {
        exitPromise.catch(() => {

          /* failed to exit fullscreen, not much to do.... */});
      }
      return;
    }

    // If not already in fullscreen, attempt to enter it
    let enterPromise = null;
    const videoContainerRef: TSFix = videoContainer.current;
    if (videoContainerRef.requestFullscreen) enterPromise = videoContainerRef.requestFullscreen();else
    if (videoContainerRef.webkitRequestFullscreen) enterPromise = videoContainerRef.webkitRequestFullscreen();else
    if (videoContainerRef.mozRequestFullScreen) enterPromise = videoContainerRef.mozRequestFullScreen();else
    if (videoContainerRef.msRequestFullscreen) enterPromise = videoContainerRef.msRequestFullscreen();
    if (enterPromise) {
      enterPromise.catch(() => {

        /* failed to enter fullscreen, not much to do.... */});
    }
  };

  const hoistMethods = () => {
    const methods = {
      seek,
      pause,
      play
    };

    setVideoMethods(methods);
  };

  const cleanupPostMessageHandler = () => {
    if (typeof window === 'undefined') return;

    window.removeEventListener('message', handlePostMessage);
  };

  const handleShowControls = () => {
    if (showControls || !canControlControlsVisibility) return;
    setShowControls(true);
    if (onControlsShown) onControlsShown();
  };

  const handleHideControls = debounce(() => {
    if (!showControls || video?.current?.paused || !canControlControlsVisibility) return;
    setShowControls(false);
    if (onControlsHidden) onControlsHidden();
  }, 2000);

  const refreshHideControlsDebounce = () => {
    handleShowControls();
    handleHideControls();
  };

  const isWithinBounds = (event: TSFix) => {
    if (event.relatedTarget && event.relatedTarget.classList && event.relatedTarget.classList.contains('wvp-overlay')) {
      refreshHideControlsDebounce();
    } else if (showControls || !video?.current?.paused) {
      hideControlsOnBlur();
    }
  };

  const hideControlsOnBlur = debounce(() => {
    if (canControlControlsVisibility) setShowControls(false);
  }, 1000);

  const reload = () => {
    if (window) window.location.reload();
  };

  const toggleMute = () => {
    setIsMuted((prev) => {
      const newValue = !prev;
      sendPostMessage('fg-wvp-onMute', { muted: newValue });
      return newValue;
    });
  };

  const togglePlayback = () => {
    if (!video || !video.current) return;
    if (video.current.paused) {
      refreshHideControlsDebounce();
      const promise = video.current.play();
      // Autoplay was prevented - https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
      // Supposed to fallback to the pause button if this fails
      if (promise !== undefined) promise.catch(() => {});
    } else {
      video.current.pause();
    }
  };

  const toggleCaptions = () => {
    setClosedCaptions((prev) => {
      const newValue = !prev;
      if (allowStorage) setStorageItem(localStorageKeys.closedCaptions, newValue);
      return newValue;
    });
  };

  const handleSetPlaybackRate = (currentPlaybackRate: number) => {
    setPlaybackRate(currentPlaybackRate);
    if (allowStorage) setStorageItem(localStorageKeys.playbackRate, currentPlaybackRate);
    if (video && video.current) video.current.playbackRate = currentPlaybackRate;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLikeUpdate = useCallback(
    throttle((shouldLike: boolean) => {
      if (likes.allowLikes) {
        const formData = new FormData();
        formData.append('groupId', likes.groupId?.toString());
        formData.append('responseId', likes.responseId);

        // if the response is already liked then click on like button will unlike (delete that like)
        if (shouldLike) {
          formData.append('_action', 'create');
          likeFetcher.submit(formData, { method: 'post', action: resourceRoutes.like });
        } else {
          formData.append('_action', 'delete');
          likeFetcher.submit(formData, { method: 'delete', action: resourceRoutes.like });
        }

        if (setResponseVideos && (entity?.type === 'Response' || entity?.type === 'Comment')) {
          setResponseVideos((prev) => {
            if (prev) {
              const likedVideo = prev.findIndex((response) => response.id === entity?.item?.id);
              if (likedVideo > -1) {
                const offset = shouldLike ? 1 : -1;
                prev[likedVideo].liked = shouldLike;
                prev[likedVideo].like_count += offset;
              }
            }
            return prev;
          });
        }

        setHasLiked(shouldLike);
      }
    }, 1000),
    [entity, likes.allowLikes, likeFetcher, setResponseVideos]
  );

  /* This is causing a video skip on the captions cue changes, removing this breaks the live updating of edit captions */
  // // This is a hack. It jiggles the video currentTime whenever the track cue changes so that the captions
  // // on the video update. This allows the user to see their caption changes in real time as they are typing.
  // useEffect(() => {
  //   if (trackRef.current) {
  //     trackRef.current.oncuechange = () => {
  //       if (video.current) video.current.currentTime += 0.000001;
  //     };
  //   }

  //   // In this case, we don't care that the component doesn't rerender, we just need the .current to exist
  //   // before we can slap on the oncuechange func.
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [trackRef.current]);

  // include the profile information to the response, to display the profile picture.
  useEffect(() => {
    if (entity && entity.type === 'Comment') {
      const imageUrl = commentUsers && commentUsers[entity.item?.user_id]?.profile_url;
      // eslint-disable-next-line no-inner-declarations
      async function getUserProfileImageUrl() {
        if (imageUrl) {
          const res = await clientFetch(imageUrl);
          const profileJson = await res.json();
          setProfileImageUrl(profileJson?.data?.image_url);
        }
      }

      // We don't have to fetch the profile information, if the response belongs to the loggedInUser.
      if (user && entity.item.user_id !== user.id) {
        getUserProfileImageUrl();
      } else {
        setProfileImageUrl(user?.image_url);
      }
    }
  }, [commentUsers, entity, user]);

  return (
    <div
      aria-hidden={ariaHidden}
      className={classNames(
        'videoPlayer__container',
        { '-fullscreen': isFullscreen },
        { '-showControls': showControls || !isPlaying },
        entityClassNameKey
      )}
      ref={videoContainer}
      onMouseMove={refreshHideControlsDebounce}
      onFocus={handleShowControls}
      onMouseLeave={(e) => isWithinBounds(e)}
      onBlur={(e) => isWithinBounds(e)}
      onContextMenu={(e) => e.preventDefault()}
      style={{ ...style }}>

      {!error && poster &&
      <img
        src={`${poster}?size=xsmall`}
        alt=""
        loading="lazy"
        className="videoPlayer__background"
        onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />}


      {error && <ErrorScreen handleRetry={reload} />}

      {isLoading && !playsInline &&
      <div className="videoPlayer__loader">
          <Loader />
        </div>}

      {!error &&
      <>
          {!isLoading && entity ?
        <>
              {entity && entity.type !== 'TopicFocus' && lastMatch.handle?.id !== HandleIds.Comments &&
          <div className="videoPlayer__profile">
                  {entity.type !== 'Mixtape' &&
            <ProfilePicture
              className="videoPlayer__profilePic"
              image_url={
              entity.type === 'Comment' ? profileImageUrl : entity.item.profile_image_url || undefined}

              id={entity.item.user_id} />}


                  <div className="videoPlayer__profileContainer">
                    <h1 className="fk-h4 videoPlayer__profileName">{entity.item.name}</h1>
                    <p className="color__white m0 shadow">
                      {t('profileCustomCard.duration', { duration: timeAgo(entity.item.created_at) })}
                    </p>
                    {entity.type === 'Mixtape' && <p className="color__white m0 shadow">{entity.item.title}</p>}
                  </div>
                </div>}

              {entity && entity.topic && <TopicCard topic={entity.topic} />}

              {entity && entity.type !== 'TopicFocus' && entity.type !== 'Comment' &&
          <div
            className={
            entity && entity.type === 'Mixtape' ? 'videoPlayer__mixtapeButtons' : 'videoPlayer__buttons'}>


                  {prevVideo &&
            <Link
              aria-label={t('videoPlayer.prevVideo')}
              data-testid="videoPlayer__button__prevVideo"
              icon={<IcFluentChevronUp24Regular />}
              onClick={prevVideo.onClick}
              size="36"
              theme="overlay"
              to={lastMatch.handle?.id === HandleIds.Comments ? prevVideo.commentsUrl : prevVideo.url}
              variant="button" />}


                  {nextVideo &&
            entity && (
            lastVideoId !== entity.item.id ?
            <Link
              aria-label={t('videoPlayer.nextVideo')}
              className={
              entity &&
              entity.type !== 'Mixtape' &&
              classNames('videoPlayer__nextVideoButton', { '-extraPadding': !likes.allowLikes })}

              data-testid="videoPlayer__button__nextVideo"
              icon={<IcFluentChevronDown24Regular />}
              onClick={() => {
                setShowBumper(false);
                nextVideo.onClick();
              }}
              size="36"
              theme="overlay"
              to={lastMatch.handle?.id === HandleIds.Comments ? nextVideo.commentsUrl : nextVideo.url}
              variant="button" /> :


            <Button
              ref={nextButtonRef}
              aria-label={t('videoPlayer.nextVideo')}
              className={
              entity && entity.type !== 'Mixtape' ?
              classNames('videoPlayer__nextVideoButton', { '-extraPadding': !likes.allowLikes }) :
              ''}

              data-testid="videoPlayer__button__nextVideo"
              icon={<IcFluentChevronDown24Regular />}
              onClick={() => {
                setShowBumper(true);
                setTimeout(() => {
                  if (backToTopLinkRef.current) backToTopLinkRef.current.focus();
                }, 0);
              }}
              disabled={showBumper}
              size="36"
              theme="overlay" />)}



                  {likes.allowLikes &&
            <Button
              aria-label={t('videoPlayer.like')}
              data-testid="videoPlayer__button__like"
              icon={
              hasLiked ?
              <IcFluentHeart24Filled className="iconFill__red" /> :

              <IcFluentHeart24Filled className="iconFill_transparent" />}


              onClick={() => handleLikeUpdate(!hasLiked)}
              theme="overlay" />}


                  {isVideoReactionEnabled && entity.topic &&
            <VideoReactionDialog
              topic={entity.topic}
              topicResponseUrl={src}
              onClick={() => logEvent({ name: reactionEventName.REMIX_CLICK }, { topicId: entity.topic.id })}
              onGreenScreenClick={() =>
              logEvent({ name: reactionEventName.GREEN_SCREEN_CLICK }, { topicId: entity.topic.id })}

              onStitchClick={() =>
              logEvent({ name: reactionEventName.STITCH_CLICK }, { topicId: entity.topic.id })}

              isGroupLead={entity?.isGroupLead} />}



                  {entity?.commentsUrl && baseUrl &&
            <Link
              aria-label={
              lastMatch.handle?.id === HandleIds.Comments ?
              t('videoPlayer.closeComments') :
              t('videoPlayer.openComments')}

              data-testid="videoPlayer__button__toggleSidebarComments"
              icon={<IcFluentComment24Filled />}
              id="comment-button"
              theme="overlay"
              to={lastMatch.handle?.id === HandleIds.Comments ? baseUrl : entity.commentsUrl}
              variant="button" />}


                  {entity && entity.type === 'Response' &&
            <ResponseActionsDropdown
              id="playerDropdownId"
              isGroupLead={entity?.isGroupLead}
              isUsernameGroup={isUsernameGroup}
              backRoute={backRoute}
              captionsUrl={`./captions?${searchParams.toString()}`}
              topic={entity.topic}
              response={entity.item} />}


                  {entity && entity.type === 'StandaloneResponse' &&
            <MyVideosActionsDropdown id="playerDropdownId" backRoute={backRoute} response={entity.item} />}

                </div>}

            </> :

        // Doesn't like returning false
        <></>}


          <video
          id={id}
          className="videoPlayer"
          ref={video}
          src={playbackVideoUrl}
          poster={autoPlay ? '' : poster}
          autoPlay={autoPlay}
          muted={isMuted}
          onMouseDown={(e) => {
            // There's an issue with native inline video player handling and programmatic triggering of events
            // where it will call both the native controls events as well as this one
            if (playsInline) return;

            e.preventDefault();
            togglePlayback();
            if (playButton && playButton.current && playButton.current.focus) {
              playButton.current.focus();
            }
          }}
          playsInline={playsInline}
          controls={playsInline}
          controlsList="nodownload">

            {captionsSrc &&
          <track
          // This is causing a video skip on the captions cue changes, removing this breaks the live updating of edit captions
          // ref={trackRef}
          kind="captions"
          label="English"
          srcLang="en"
          src={captionsSrc}
          default />}


          </video>

          {closeButton &&
        <Button
          data-testid="videoPlayer__button__close"
          onClick={onClose}
          icon={<IcFluentDismiss24Regular />}
          theme="overlay"
          className="videoPlayer__closeButton"
          aria-label={t('shared.closeVideoPlayer')}
          size="36" />}


          <ClosedCaptions activeCue={(activeCue as TSFix)} closedCaptions={closedCaptions} />

          {showBumper && nextVideo &&
        <div className="videoPlayer__bannerWrapper">
              <div className="videoPlayer__banner">
                <PartyingFace className="videoPlayer__icon" />
                <div className="videoPlayer__bannerContent">
                  <h1 className="fk-h3 m0">{t('videoPlayer.seenItAll')}</h1>
                  <p className="videoPlayer__bannerText">
                    <Trans i18nKey="videoPlayer.allCaughtUp">
                      You're all caught up with your group!
                      <IcFluentArrowUp24Regular className="videoPlayer__arrow iconFill__fg-1" width="20px" />
                      <Link
                    onClick={() => {
                      setShowBumper(false);
                      nextVideoRef?.current?.onClick();
                    }}
                    ref={backToTopLinkRef}
                    to={commentsOpen.current ? nextVideoRef?.current?.commentsUrl : nextVideoRef?.current?.url}
                    data-testid="videoPlayer__link__backToTop">

                        Back to top
                      </Link>
                    </Trans>
                  </p>
                  <Button
                data-testid="videoPlayer__button__closeBanner"
                className="videoPlayer__bannerClose"
                icon={<IcFluentDismiss24Regular />}
                onClick={() => {
                  setShowBumper(false);
                  setTimeout(() => {
                    if (nextButtonRef.current) nextButtonRef.current.focus();
                  }, 0);
                }}
                size="26"
                theme="tertiary" />

                </div>
              </div>
            </div>}


          {children &&
        React.Children.map(children, (child) => {
          if (!child || child.type !== VideoPlayerControls) return child;
          // all prop types without defaults for VideoPlayerControls are required here
          return React.cloneElement(child, {
            activeCue,
            canControlControlsVisibility,
            closedCaptions,
            currentTime,
            duration,
            error,
            handleSetPlaybackRate,
            toggleFullscreen: handleToggleFullscreen,
            hasClosedCaptions: !!captionsSrc,
            hasViewed: hasViewedRef.current,
            isFullscreen,
            isLoading,
            isMuted,
            isPlaying,
            playButtonRef: playButton,
            playbackRate,
            portalTargetID: portalTargetIdentifier,
            setPlaybackRate: handleSetPlaybackRate,
            seek,
            showControls,
            src,
            toggleCaptions,
            toggleMute,
            togglePlayback,
            track
          });
        })}
        </>}

      <div id={portalTargetIdentifier} />
    </div>);

};

VideoPlayer.Controls = VideoPlayerControls;

export default VideoPlayer;