import { Button, ButtonSubmit, Checkbox, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { FlipResponse } from 'types';

type Props = {
  backRoute?: string;
  response: FlipResponse;
  onRequestClose: OnRequestCloseType;
};

const DeleteResponseModal = ({ backRoute, response, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const [checked, setChecked] = useState(false);
  const { grid_id, topic_id, id } = response;

  useEffect(() => {
    if (fetcher.type === 'done') {
      onRequestClose(t('shared.deleteModalClosed'));
    }
  }, [fetcher, fetcher.type, onRequestClose, t]);

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form
        method="post"
        action={routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(grid_id || 'null', topic_id || 'null')}
      >
        <h1 className="fk-modalHeader">{t('shared.deleteResponse')}</h1>
        <p className="fk-modalBody">
          <Trans i18nKey="deleteResponseModal.responseDeleteConfirm" values={{ firstName: response.first_name }}>
            Deleting <b>{response.first_name}'s</b> response will remove it from the topic. This is permanent and cannot
            be undone. The member will still be able to access their video in their profile.
          </Trans>
        </p>
        <Checkbox
          wrapperClassName="mt1"
          data-testid="deleteResponseModal__checkbox__confirmDelete"
          checked={checked}
          onChange={() => setChecked(prev => !prev)}
        >
          {t('shared.wantToDeleteResponse')}
        </Checkbox>
        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="deleteResponseModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="deleteResponse"
            theme="danger"
            data-testid="deleteResponseModal__button__removeOrDelete"
            loading={fetcher.state !== 'idle'}
            disabled={!checked}
          >
            {t('common.delete')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="back_url" value={backRoute || ''} />
        <input type="hidden" name="id" value={id} />
        <input type="hidden" name="grid_id" value={grid_id} />
        <input type="hidden" name="topic_id" value={topic_id} />
      </fetcher.Form>
    </Modal>
  );
};

export default DeleteResponseModal;
