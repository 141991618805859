export function pauseAllOtherVideos(exceptionVideoID?: string) {
  if (typeof window === 'undefined' || !document || exceptionVideoID?.includes('undefined')) return;
  // Get all videos to pause. If passed an exception video ID, don't attempt
  // to pause that video.
  let videos = [...document.querySelectorAll('video')];

  if (exceptionVideoID) videos = videos.filter(video => video.id !== exceptionVideoID);

  videos.forEach(video => {
    if (!video.paused) video.pause();
  });
}
