import { Button, ButtonSubmit, Loader, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Combobox from '~/components/FkWrappers/Combobox';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import { formatName } from '~/helper/formatting';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, FlipResponse, Group, Topic } from 'types';

type Props = {
  onRequestClose: OnRequestCloseType;
  response: FlipResponse;
};

const DuplicateResponseModal = ({ onRequestClose, response }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<TSFix>(); // todo: type
  const groupsFetcher = useFetcher<ApiResponse<Group[]>>();
  const topicsFetcher = useFetcher<ApiResponse<Topic[]>>();
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [selectedTopic, setSelectedTopic] = useState<Topic>();
  const isSubmitting =
    fetcher.state === 'submitting' || fetcher.type === 'actionReload' || fetcher.type === 'actionRedirect';
  const delimiter = '·';

  const isGroupsLoading =
    groupsFetcher.state === 'loading' || (groupsFetcher.state === 'idle' && groupsFetcher.type !== 'done');
  const isTopicsLoading =
    topicsFetcher.state === 'loading' || (topicsFetcher.state === 'idle' && topicsFetcher.type !== 'done');
  const submissionSuccess = fetcher.type === 'done' && !fetcher.submission && !fetcher.data;
  const submissionPending = fetcher.type === 'done' && ['queued', 'working'].includes(fetcher.data?.status);
  const submissionError = fetcher.type === 'done' && fetcher.data?.status === 'failed';

  useEffect(() => {
    const shouldLoadGroups = groupsFetcher.state === 'idle' && !groupsFetcher.data;
    const params = {
      load_all: 'true',
      order_by: shouldLoadGroups ? '-name' : '-title',
      per_page: '250',
      standalone: 'true',
    };

    const formattedParams = new URLSearchParams(params);
    if (shouldLoadGroups) {
      groupsFetcher.load(`${resourceRoutes.myGroups}?${formattedParams.toString()}`);
    }
  }, [groupsFetcher, response.grid_id, selectedGroup]);

  const loadTopics = (groupId: number) => {
    const params = {
      group_id: groupId.toString(),
      load_all: 'true',
      manage: 'true',
      order_by: '-title',
      per_page: '250',
      standalone_only: 'true',
    };
    const formattedParams = new URLSearchParams(params);
    topicsFetcher.load(`${resourceRoutes.myTopics}?${formattedParams.toString()}`);
  };

  function parseSelection<T>(displaySelection: string, list: T[]) {
    const selectedVanityToken = displaySelection
      .substring(displaySelection.lastIndexOf(delimiter) + 1, displaySelection.length)
      .trim();
    const selection: T = (list as TSFix).find((group: Group | Topic) => group.vanity_token === selectedVanityToken);
    return selection;
  }

  const clearErrors = () => {
    if (fetcher.data?.errors) {
      fetcher.data.errors = {};
    }
  };

  if (submissionSuccess)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('shared.duplicateComplete')}</h1>
        <p className="fk-modalBody">{t('shared.duplicateResponseRedirected', { topicName: selectedTopic?.title })}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateResponseModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionError)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('common.oops')}</h1>
        <p className="fk-modalBody">{t('moveResponseModal.errorMovedResponse')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateResponseModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionPending)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('shared.stillWorking')}</h1>
        <p className="fk-modalBody">{t('duplicateResponseModal.duplicationResponseInProgress')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateResponseModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  return (
    <Modal onClose={onRequestClose}>
      <fetcher.Form
        method="post"
        action={routes.GROUPS_ID_TOPICS_ID_RESPONSES_ID_FUNC(
          response.grid_id || 'null',
          response.topic_id || 'null',
          response.id,
        )}
      >
        <h1 className="fk-modalHeader">{t('shared.duplicateResponse')}</h1>
        <p className="fk-modalBody">
          {t('duplicateResponseModal.selectWhereToDuplicate', { displayName: formatName(response) })}
        </p>

        {!isGroupsLoading ? (
          <div className="mb1">
            <Combobox
              name="duplicate_response_groups"
              label={t('shared.selectAGroup')}
              onSelect={(selectionString: string) => {
                if (!groupsFetcher.data) return;
                const selection = parseSelection(selectionString, groupsFetcher.data.data as Group[]);
                setSelectedGroup(selection);
                loadTopics(selection.id);
                clearErrors();
              }}
              placeholder={t('shared.selectOrTypeToSearch')}
              error={fetcher.data?.errors?.groupId && !isSubmitting ? t('shared.selectDestinationGroup') : ''}
              autocomplete="off"
            >
              {groupsFetcher.data?.data.map(group => {
                const count = t('shared.numResponses', { count: group.all_response_count ?? group.response_count });
                const displayValue = `${group.name} ${delimiter} ${count} ${delimiter} ${group.vanity_token}`;
                return <Combobox.Option value={displayValue} key={group.id} />;
              })}
            </Combobox>
          </div>
        ) : (
          <Loader container />
        )}

        {!isTopicsLoading ? (
          <div className="mb1">
            <Combobox
              name="duplicate_response_topics"
              label={t('shared.selectATopic')}
              onSelect={(selectionString: string) => {
                if (!topicsFetcher.data) return;
                const selection = parseSelection(selectionString, topicsFetcher.data.data);
                setSelectedTopic(selection);
                clearErrors();
              }}
              placeholder={t('shared.selectOrTypeToSearch')}
              error={fetcher.data?.errors?.topicId && !isSubmitting ? t('shared.pleaseSelectADestinationTopic') : ''}
              autocomplete="off"
            >
              {topicsFetcher.data?.data.map(topic => {
                const count = t('shared.numResponses', { count: topic.all_response_count });
                const displayValue = `${topic.title} ${delimiter} ${count} ${delimiter} ${topic.vanity_token}`;
                return <Combobox.Option value={displayValue} key={topic.id} />;
              })}
            </Combobox>
          </div>
        ) : (
          <>{selectedGroup && <Loader container />}</>
        )}
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="duplicateResponseModal__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="duplicateResponse"
            data-testid="duplicateResponseModal__button__duplicate"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('shared.duplicate')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="topic_id" value={selectedTopic?.id ?? ''} />
        <input type="hidden" name="response_id" value={response.id} />
        <input type="hidden" name="duplicate_group_id" value={selectedGroup?.id ?? ''} />
        <input type="hidden" name="duplicate_topic_id" value={selectedTopic?.id ?? ''} />
      </fetcher.Form>
    </Modal>
  );
};

export default DuplicateResponseModal;
