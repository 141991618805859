import {
  Dropdown,
  IcFluentDelete24Regular,
  IcFluentEdit24Regular,
  IcFluentMoreHorizontal24Regular,
  ModalConsumer,
  IcFluentAddSquare24Regular,
  IcFluentDismiss24Regular,
  IcFluentArrowDownload24Regular,
  IcFluentImageCopy24Regular,
  IcFluentClosedCaptions24Regular,
  IcFluentTextboxAlignBottom24Regular } from
'@flipgrid/flipkit';
import { useFetcher, useLocation, useMatches } from '@remix-run/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TopicImageCircle, { links as topicImageCircleStyles } from './TopicImageCircle';
import DropdownLink from '../FkWrappers/DropdownLink';
import FeatureFlag from '../FkWrappers/FeatureFlag';
import DeleteMyVideoModal from '../Modals/DeleteMyVideoModal';
import EditMyVideoDetailsModal from '../Modals/EditMyVideosDetailsModal';
import PostToTopicModal, { links as postToTopicModalStyles } from '../Modals/MyVideos/PostToTopicModal';
import RemoveFromTopicModal from '../Modals/MyVideos/RemoveFromTopicModal';
import ShareButton, { links as shareButtonStyles } from '../Utility/Buttons/ShareButton';
import endpoints from '~/constants/endpoints';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import globalContext from '~/contexts/globalContext';
import responseListContext from '~/contexts/responseListContext';
import { filenameImg, filenameVideo, isFlagPresent } from '~/helper/helper';
import myVideosActionsDropdownStyles from '~/styles/components/MyVideos/MyVideosActionsDropdown.css';

import type { KeyboardEvent, MouseEvent } from 'react';
import type { FlipResponse, Topic } from 'types';

export const links = () => [
...shareButtonStyles(),
...postToTopicModalStyles(),
...topicImageCircleStyles(),
{ rel: 'stylesheet', href: myVideosActionsDropdownStyles }];


type Props = {
  backRoute?: string;
  captionsUrl?: string;
  className?: string;
  id?: string;
  response: FlipResponse;
  size?: '26';
  videoMethods?: {pause: () => void;} | null;
};

const MyVideosActionsDropdown = ({ backRoute, captionsUrl, className, id, response, size, videoMethods }: Props) => {
  const { t } = useTranslation();
  const { setTopics, setTopicsMetadata, setVideos, topics, topicsMetadata } = useContext(responseListContext);
  const [topic, setTopic] = useState<Topic | undefined>(undefined);
  const fetcher = useFetcher();
  const location = useLocation();
  const [root] = useMatches();
  const { env } = root.data;
  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const pauseVideo = () => {
    if (videoMethods && videoMethods.pause) videoMethods.pause();
  };

  useEffect(() => {
    if (fetcher.data) setTopic(fetcher.data.data);
  }, [fetcher]);

  const videoLinkUrl = `${response.video_url}?filename=${filenameVideo(response)}&download=true`;
  const imageLinkUrl = `${response.image_url}?filename=${filenameImg(response)}&download=true`;

  return (
    <ModalConsumer>
      {({ showModal }) =>
      <Dropdown
        className="myVideosActionsDropdown"
        button={
        <Dropdown.Button
          id={id}
          className={className}
          data-testid="myVideosActionsDropdown__button__dropdown"
          aria-controls=""
          aria-label={t('myVideosActionsDropdown.dropdownButton')}
          theme="overlay"
          icon={<IcFluentMoreHorizontal24Regular />}
          size={size}
          onClick={(e: MouseEvent | KeyboardEvent) => {
            e.stopPropagation();
            e.preventDefault();
            if (response.topic_id && (!topic || topic.id !== response.topic_id)) {
              const qs = new URLSearchParams([
              ['_action', 'loadTopic'],
              ['id', response.topic_id?.toString() || ''],
              ['group_id', response.grid_id?.toString() || '']]
              );
              fetcher.load(resourceRoutes.topic + `?${qs}`);
            } else if (!response.topic_id) setTopic(undefined);
          }} />}>



          {isDeprecated ? null : response.grid_id && response.topic_id ?
        <div className="myVideosActionsDropdown__postedTo">
              <Dropdown.Item
            href={routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(response.grid_id, response.topic_id)}
            data-testid="myVideosActionsDropdown__dropdownItem__goToTopic">

                <p className="myVideosActionsDropdown__text">{t('myVideosActionsDropdown.postedToThreeDots')}</p>
                <div className="myVideosActionsDropdown__container">
                  <TopicImageCircle topic={topic} />
                  <div className="myVideosActionsDropdown__textContainer">
                    <p className="mt0 mb0">{response.grid_active ? response.grid_name : t('shared.hiddenGroup')}</p>
                    <h3 className="mt0 mb0 fk-h4">
                      {response.topic_active ? response.topic_title : t('shared.hiddenTopic')}
                    </h3>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
            aria-label={t('myVideosActionsDropdown.removeFromTopic')}
            data-testid="myVideosActionsDropdown__button__dismissIcon"
            className="myVideosActionsDropdown__remove"
            icon={<IcFluentDismiss24Regular height="16" width="16" />}
            onSelect={() => {
              showModal(RemoveFromTopicModal, {
                response
              });
            }} />

            </div> :

        <Dropdown.Item
          onSelect={() => {
            pauseVideo();
            showModal(PostToTopicModal, {
              response,
              setTopics,
              setTopicsMetadata,
              setVideos,
              showModal,
              topics,
              topicsMetadata
            });
          }}
          data-testid="myVideosActionsDropdown__dropdownItem__postToTopic"
          icon={<IcFluentAddSquare24Regular />}>

              {t('myVideosActionsDropdown.postToThreeDots')}
            </Dropdown.Item>}


          {isDeprecated ? null :
        <Dropdown.Item
          data-testid="myVideoActionsDropdown__dropdownItem__edit"
          icon={<IcFluentEdit24Regular />}
          onSelect={() => {
            pauseVideo();
            showModal(EditMyVideoDetailsModal, { response });
          }}>

              {t('shared.editDetails')}
            </Dropdown.Item>}

          <ShareButton
          displayType="dropdown"
          showModal={showModal}
          entity={{
            item: response,
            type: 'MyVideos'
          }}
          onSelect={() => {
            pauseVideo();
          }} />


          <Dropdown.Divider />

          {isDeprecated ? null :
        <>
              <FeatureFlag match="pangea-standaloneCaptions">
                {!location.pathname.includes('captions') &&
            <DropdownLink
              data-testid="myVideosActionsDropdown__dropdownItem__editCaptions"
              icon={<IcFluentClosedCaptions24Regular />}
              to={captionsUrl || './captions'}>

                    {t('shared.editCaptions')}
                  </DropdownLink>}

              </FeatureFlag>
              <FeatureFlag match="pangea-standaloneCaptions" showWhenOff>
                {!location.pathname.includes('captions') && response.grid_id &&
            <DropdownLink
              data-testid="myVideosActionsDropdown__dropdownItem__editCaptions"
              icon={<IcFluentClosedCaptions24Regular />}
              to={captionsUrl || './captions'}>

                    {t('shared.editCaptions')}
                  </DropdownLink>}

              </FeatureFlag>
            </>}

          {/* {response.transcript && (
           <Dropdown.Item
             data-testid="myVideosActionsDropdown__dropdownItem__downloadCaptions"
             icon={<IcFluentTextboxAlignBottom24Regular />}
             href={endpoints.RESPONSES_ID_TRANSCRIPT_TXT_FUNC(response.id, env.CLIENT_API_URL)}
             newTab
           >
             {t('common.downloadCaptions')}
           </Dropdown.Item>
          )} */}

          <Dropdown.Item
          href={videoLinkUrl}
          newTab
          data-testid="myVideoActionsDropdown__dropdownItem__downloadVideo"
          icon={<IcFluentArrowDownload24Regular />}>

            {t('common.downloadVideo')}
          </Dropdown.Item>
          <Dropdown.Item
          href={imageLinkUrl}
          newTab
          data-testid="myVideoActionsDropdown__dropdownItem__downloadCoverImg"
          icon={<IcFluentImageCopy24Regular />}>

            {t('common.downloadCoverImg')}
          </Dropdown.Item>

          {isDeprecated ? null :
        <>
              <Dropdown.Divider />
              <Dropdown.Item
            data-testid="myVideoActionsDropdown__dropdownItem__delete"
            icon={<IcFluentDelete24Regular />}
            onSelect={() => showModal(DeleteMyVideoModal, { response, backRoute })}>

                {t('shared.deleteVideo')}
              </Dropdown.Item>
            </>}

        </Dropdown>}

    </ModalConsumer>);

};

export default MyVideosActionsDropdown;