import { useTranslation } from 'react-i18next';

import CopyShareLink, { links as copyShareLinkStyles } from '~/components/Share/CopyShareLink';
import SocialLinks, { links as socialLinksStyles } from '~/components/Share/components/SocialLinks';
import routes from '~/constants/routes';
import socialContentStyles from '~/styles/components/Share/SocialContent.css';

import type { Group, FlipResponse, Topic, Comment, Mixtape, GroupAccessControls, MixtapeResponse } from 'types';

export const links = () => [
...socialLinksStyles(),
...copyShareLinkStyles(),
{ rel: 'stylesheet', href: socialContentStyles }];


type Entity =
{
  type: 'Response' | 'MyVideos';
  item: FlipResponse;
  token: string;
  isFetchingToken: boolean;
} |
{
  type: 'Comment';
  item: Comment;
  token: string;
  isFetchingToken: boolean;
} |
{
  type: 'MixtapeResponse';
  item: MixtapeResponse;
  mixtapeToken: string;
} |
{
  type: 'Group';
  item: Group;
} |
{
  type: 'Topic';
  item: Topic;
} |
{
  type: 'Mixtape';
  item: Mixtape;
};

type Props = {
  accessControlType: GroupAccessControls | undefined;
  download?: {url: string;displayName?: string;};
  entity: Entity;
  hideQrCodeBtn?: boolean;
  shareBaseUrl?: string;
  shareDisplayName?: string;
  vanityToken: string;
};

const SocialContent = ({
  accessControlType,
  download,
  entity,
  hideQrCodeBtn = false,
  shareBaseUrl,
  shareDisplayName,
  vanityToken
}: Props) => {
  const { type } = entity;
  const { t } = useTranslation();
  const isStudentOrNullAccessControlType = accessControlType === 'student' || accessControlType === null;

  if (entity.type === 'Comment' || entity.type === 'Response' || entity.type === 'MyVideos') {
    return (
      <div className="socialContent">
        <div className="socialContent__inputContainer">
          <CopyShareLink
            rootPath={`${shareBaseUrl}/s/`}
            label={t('shared.shareVideo')}
            shareToken={entity.token}
            leftButtonTheme="secondary"
            isStudentGroup={accessControlType === 'student'}
            includeLeftButton />

          <p className="socialContent__bodyText">{t('shared.onlyShareThisLink')}</p>
          <hr className="socialContent__spacer" />
          <div className="socialContent__socialLinks">
            <SocialLinks
              download={download}
              url={`https://${shareBaseUrl}/s/${entity.token}`}
              aspectRatio={entity.item.aspect_ratio}
              type={type}
              token={entity.token}
              isLoading={entity.isFetchingToken} />

          </div>
        </div>
      </div>);

  }

  if (entity.type === 'MixtapeResponse') {
    return (
      <div className="socialContent">
        <div className="socialContent__inputContainer">
          <CopyShareLink
            rootPath={`${shareBaseUrl}/${entity.mixtapeToken}/`}
            label={t('shared.shareVideo')}
            vanityToken={entity.item.id?.toString()}
            leftButtonTheme="secondary"
            includeLeftButton
            isStudentGroup={false} />


          <p className="socialContent__bodyText">{t('shared.onlyShareThisLink')}</p>
          <hr className="socialContent__spacer" />
          <div className="socialContent__socialLinks">
            <SocialLinks
              download={download}
              url={`https://${shareBaseUrl}/${entity.mixtapeToken}/${entity.item.id}`}
              aspectRatio={entity.item.aspect_ratio}
              type={type}
              token={entity.item.id?.toString()} />

          </div>
        </div>
      </div>);

  }

  return (
    <div className="socialContent">
      {!(isStudentOrNullAccessControlType && type === 'Group') && shareBaseUrl &&
      <div className="socialContent__inputContainer">
          <CopyShareLink
          rootPath={type === 'Mixtape' ? routes.MIXTAPE_ROOT_URL_FUNC(shareBaseUrl) : `${shareBaseUrl}/`}
          label={type === 'Topic' ? t('shared.shareTopic') : t('shared.inviteByLink')}
          vanityToken={vanityToken}
          leftButtonTheme="secondary"
          isStudentGroup={accessControlType === 'student'}
          includeLeftButton />

          {type !== 'Mixtape' &&
        <p className="socialContent__warning">
              {accessControlType === 'pln' && type === 'Topic' ?
          t('socialContent.anyoneWithALink') :
          t('common.onlyPeopleApproved')}
            </p>}

        </div>}

      <hr className="socialContent__spacer" />
      <SocialLinks
        className="socialContent__socialLinks"
        download={download}
        qr={{
          url: `https://${shareBaseUrl}/${vanityToken}`,
          displayName: shareDisplayName
        }}
        url={`https://${shareBaseUrl}/${vanityToken}`}
        type={type}
        aspectRatio=""
        hideQrCodeBtn={hideQrCodeBtn}
        hideCopyShareLinkBtn={!isStudentOrNullAccessControlType || type !== 'Group'}
        token={vanityToken} />

    </div>);

};

export default SocialContent;