// Inspiration on dynamic sizing from https://github.com/fireship-io/229-multi-level-dropdown
import { useEventListener } from '@flipgrid/flipkit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import HomePanel from './Panels/HomePanel';
import Panel from './Panels/Panel';
import PlaybackSpeedPanel from './Panels/PlaybackSpeedPanel';
import ReportPanel from './Panels/ReportPanel';

// Account for the settings menu border when calculating the menu dimensions dynamically
const MENU_TOTAL_BORDER_WIDTH = 5;

type Props = {
  close: () => void;
  isFullscreen?: boolean;
  isPlaying?: boolean;
  looped?: boolean;
  onReportVideo?: () => void;
  playbackRate: number;
  portalTargetID: string;
  setPlaybackRate: (value: number) => void;
  toggleFullscreen?: () => void;
  toggleLooped?: () => void;
  togglePlayback?: () => void;
};

function SettingsMenu({
  close,
  isFullscreen,
  isPlaying,
  looped,
  onReportVideo,
  playbackRate,
  portalTargetID,
  setPlaybackRate,
  toggleFullscreen,
  toggleLooped,
  togglePlayback,
}: Props) {
  const [menu, setMenu] = useState('HOME');
  const [menuDimensions, setMenuDimensions] = useState({ height: 0, width: 0 });
  const menuRef = useRef<HTMLDivElement>(null);

  const calculateHeight = (element: HTMLElement) => {
    setMenuDimensions({
      height: element.clientHeight + MENU_TOTAL_BORDER_WIDTH,
      width: element.clientWidth + MENU_TOTAL_BORDER_WIDTH,
    });
  };

  useEffect(() => {
    if (menuRef.current && menuRef.current.firstChild) {
      setMenuDimensions({
        height: (menuRef.current.firstChild as HTMLElement).clientHeight + MENU_TOTAL_BORDER_WIDTH,
        width: (menuRef.current.firstChild as HTMLElement).clientWidth + MENU_TOTAL_BORDER_WIDTH,
      });
    }
  }, []);

  const handleClick = useCallback(
    (e: TSFix) => {
      // Return early if we can't locate the target, or the target is the settings button
      // which has it's own handler for closing/refocusing
      // console.dir(e.target);
      if (!menuRef.current || !menuRef.current.contains) return;
      if (typeof e.target.className === 'string' && e.target.className.indexOf('-settings') > -1) return;

      // Close the menu if the target isn't contained by the menu
      if (!menuRef.current.contains(e.target)) close();
    },
    [close],
  );

  // @ts-ignore
  useEventListener('mousedown', handleClick, true, document);

  const report = () => {
    if (toggleFullscreen && isFullscreen) toggleFullscreen();
    if (onReportVideo) {
      if (isPlaying && togglePlayback) togglePlayback();
      onReportVideo();
      close();
    }
  };

  const Menu = (
    <div
      ref={menuRef}
      style={{ height: `${menuDimensions.height}px`, width: `${menuDimensions.width}px` }}
      className="settingsMenu"
    >
      <Panel active={menu === 'HOME'} className="settingsMenu__panelBackward" onEnter={calculateHeight}>
        <HomePanel
          closeMenu={close}
          hasReportAvailable={!!onReportVideo}
          looped={looped}
          playbackRate={playbackRate}
          setMenu={setMenu}
          toggleLooped={toggleLooped}
        />
      </Panel>
      <Panel
        active={menu === 'PLAYBACK'}
        className="settingsMenu__panelForward"
        onEnter={calculateHeight}
        aria-expanded="false"
      >
        <PlaybackSpeedPanel
          closeMenu={close}
          setMenu={setMenu}
          playbackRate={playbackRate}
          setPlaybackRate={setPlaybackRate}
        />
      </Panel>
      {onReportVideo && (
        <Panel active={menu === 'REPORT'} className="settingsMenu__panelForward" onEnter={calculateHeight}>
          <ReportPanel closeMenu={close} setMenu={setMenu} report={report} />
        </Panel>
      )}
    </div>
  );

  return createPortal(Menu, document.getElementById(portalTargetID) as HTMLElement);
}

export default SettingsMenu;
