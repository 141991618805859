import { Button, ButtonSubmit, Loader, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Link from '~/components/FkWrappers/Link';
import MultiSelect, { links as multiSelectStyles } from '~/components/Utility/components/MultiSelect';
import resourceRoutes from '~/constants/resourceRoutes';
import routes from '~/constants/routes';
import { formatName } from '~/helper/formatting';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { SetStateAction } from 'react';
import type { MultiValue } from 'react-select';
import type { FlipResponse, Job, MultiSelectOption, Comment, ApiResponse, Mixtape, Metadata } from 'types';

export const links: LinksFunction = () => [...multiSelectStyles()];

type Props = {
  defaults: {
    mixtapes: Mixtape[];
    mixtapesMetadata?: Metadata;
    setMixtapes: (value: SetStateAction<Mixtape[]>) => void;
    setMixtapesMetadata: (value: SetStateAction<Metadata | undefined>) => void;
  };
  item: FlipResponse | Comment;
  onRequestClose: OnRequestCloseType;
};

const AddVideoToMixtapeModal = ({ defaults, item, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher<Job>();
  const [selectedMixtapeIds, setSelectedMixtapesIds] = useState<MultiValue<MultiSelectOption>>([]);
  const mixtapeFetcher = useFetcher<ApiResponse<Mixtape[]>>();
  const isSubmitting = fetcher.state !== 'idle';
  const submissionSuccess = fetcher.type === 'done' && !fetcher.submission;
  const submissionError = fetcher.type === 'done' && fetcher.data?.status === 'failed';
  const [isLoading, setIsLoading] = useState(false);
  const [mixtapes, setMixtapes] = useState(defaults.mixtapes);
  const [mixtapesMetadata, setMixtapesMetadata] = useState(defaults.mixtapesMetadata);
  const hasNoMixtapes = !isLoading && mixtapes?.length === 0;

  useEffect(() => {
    if (mixtapeFetcher.type === 'done') {
      setIsLoading(false);
      setMixtapes(mixtapeFetcher.data?.data || []);
      setMixtapesMetadata(mixtapeFetcher.data?.metadata);
      defaults.setMixtapes(mixtapeFetcher.data?.data || []);
      defaults.setMixtapesMetadata(mixtapeFetcher.data?.metadata);
    }
  }, [mixtapeFetcher, defaults, setMixtapes, setMixtapesMetadata]);

  // here we fetch all mixtapes to be shown on the multiselect
  useEffect(() => {
    if (mixtapes?.length !== mixtapesMetadata?.pagination?.total && !isLoading) {
      setIsLoading(true);
      const params = {
        load_all: 'true',
      };
      const formattedParams = new URLSearchParams(params);
      mixtapeFetcher.load(`${resourceRoutes.mixtapes}?${formattedParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMixtapeTitles = () => {
    return selectedMixtapeIds.map(mix => <li key={mix.value}>{mix.label}</li>);
  };

  const hasError = () => {
    const hasSubmitted = fetcher.type === 'done' || isSubmitting;
    return selectedMixtapeIds.length === 0 && hasSubmitted;
  };

  if (submissionSuccess)
    return (
      <Modal show onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('addVideoToMixtapeModal.videoAdded')}</h1>
        <p className="fk-modalBody">
          <Trans i18nKey="addVideoToMixtapeModal.addedVideoToMixtape" values={{ name: formatName(item) }}>
            You've added <b>{formatName(item)}'s</b> video to the following Mixtape(s):
          </Trans>
        </p>
        <ul>{renderMixtapeTitles()}</ul>
        <p className="fk-modalBody">
          <Trans i18nKey="addVideoToMixtapeModal.checkOutYourMixtapes">
            Check out{' '}
            <Link to={routes.MIXTAPES} onClick={onRequestClose} data-testid="addVideoToMixtapeModal__link__mixtapes">
              your Mixtapes
            </Link>{' '}
            or return to the topic.
          </Trans>
        </p>

        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="addVideoToMixtapeModal__button__cancel" onClick={onRequestClose}>
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  if (submissionError)
    return (
      <Modal onClose={onRequestClose}>
        <h1 className="fk-modalHeader">{t('common.oops')}</h1>
        <p className="fk-modalBody">{t('addVideoToMixtapeModal.addToMixtapeError')}</p>
        <Modal.Actions className="mt2">
          <Button onClick={onRequestClose} theme="secondary" data-testid="addVideoToMixtapeModal__button__close">
            {t('common.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

  return (
    <Modal show onClose={onRequestClose}>
      <fetcher.Form method="post" action={routes.MIXTAPES}>
        <h1 className="fk-modalHeader">{t('shared.addToMixtape')}</h1>
        <p className="fk-modalBody">{t('shared.mixtapeExplanationLong')}</p>

        {!isLoading && mixtapes?.length > 0 && (
          <MultiSelect
            wrapperClassName="mt1 mb1"
            displayLabel={t('shared.mixtapes')}
            data-testid="addVideoToMixtapeModal__multiSelect__mixtapesSelector"
            onChange={(selected: MultiValue<MultiSelectOption>) => setSelectedMixtapesIds(selected)}
            options={mixtapes?.map(
              (mix: { id: string | number; title: string }) =>
                ({ value: mix.id, label: mix.title }) as MultiSelectOption,
            )}
            placeholder={t('addVideoToMixtapeModal.selectMixtape')}
            error={hasError() ? t('addVideoToMixtapeModal.selectDestinationMixtapeError') : undefined}
          />
        )}

        {isLoading && <Loader container />}
        {hasNoMixtapes && <div className="fk-fontSize__14">{t('shared.noMixtapesFound')}</div>}

        <Modal.Actions className="mt2">
          <Button theme="secondary" data-testid="addVideoToMixtapeModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          {!isLoading && hasNoMixtapes ? (
            <Link variant="button" data-testid="addVideoToMixtapeModal__button__createMixtapeLink" to={routes.MIXTAPES}>
              {t('common.create')}
            </Link>
          ) : (
            <ButtonSubmit
              name="_action"
              value="addToMixtape"
              type="submit"
              data-testid="addVideoToMixtapeModal__button__add"
              loading={isSubmitting}
            >
              {t('common.add')}
            </ButtonSubmit>
          )}
        </Modal.Actions>
        <input type="hidden" name="response_id" value={item.id} />
        <input
          type="hidden"
          name="channel_ids"
          value={JSON.stringify(selectedMixtapeIds.map(option => option.value.toString()))}
        />
      </fetcher.Form>
    </Modal>
  );
};

export default AddVideoToMixtapeModal;
