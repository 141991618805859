import { ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../FkWrappers/Input';
import TextArea from '../FkWrappers/TextArea';
import resourceRoutes from '~/constants/resourceRoutes';
import { formatName } from '~/helper/formatting';
import { USER_ERRORS } from '~/helper/usernameCsvUploadHelpers';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { FlipResponse } from 'types';

type Props = {
  response: FlipResponse;
  onRequestClose: OnRequestCloseType;
};

const EditResponseUsernameGroupModal = ({ response, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();

  const { first_name, last_name, display_name, title, link, id, grid_id, email } = response;
  const [validation, setValidation] = useState({
    firstName: false,
    lastName: false,
    uid: false,
    uidDuplicate: false,
    email: false,
  });
  const name = formatName(response);

  useEffect(() => {
    if (fetcher.type === 'done' && Object.keys(fetcher?.data?.error).length === 0) onRequestClose();
  }, [fetcher.type, fetcher.data, onRequestClose]);

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('shared.editCreatorsResponseDetails', { name })}</h1>
      <fetcher.Form method="put" action={`${resourceRoutes.updateState}?manage=true`}>
        <div className="fk-modalPanel">
          <Input
            autoComplete="off"
            data-testid="editResponseUsernameGroupModal__input__firstName"
            defaultValue={first_name ?? null}
            error={validation.firstName ? USER_ERRORS().FIRST_NAME_REQUIRED : undefined}
            label={t('common.firstName')}
            maxLength={50}
            name="first_name"
            onChange={() => (validation.firstName ? setValidation(prev => ({ ...prev, firstName: false })) : undefined)}
            placeholder={t('common.firstName')}
            type="text"
          />
          <Input
            autoComplete="off"
            data-testid="editResponseUsernameGroupModal__input__lastName"
            defaultValue={last_name ?? null}
            error={validation.lastName ? USER_ERRORS().LAST_NAME_REQUIRED : undefined}
            label={t('common.lastName')}
            maxLength={50}
            name="last_name"
            onChange={() => (validation.lastName ? setValidation(prev => ({ ...prev, lastName: false })) : undefined)}
            placeholder={t('common.lastName')}
            type="text"
          />
          <Input
            autoComplete="off"
            data-testid="editResponseUsernameGroupModal__input__identifier"
            defaultValue={display_name ?? null}
            error={
              validation.uid
                ? USER_ERRORS().USERNAME_TOO_SHORT
                : validation.uidDuplicate
                  ? USER_ERRORS().USERNAME_OVERLAP
                  : undefined
            }
            onChange={() =>
              validation.uid || validation.uidDuplicate
                ? setValidation(prev => ({ ...prev, uid: false, uidDuplicate: false }))
                : undefined
            }
            label={t('common.displayName')}
            maxLength={50}
            name="display_name"
            placeholder={t('common.identifier')}
            type="text"
          />
          <Input
            autoComplete="off"
            data-testid="editResponseUsernameGroupModal__input__email"
            defaultValue={email ?? null}
            label={t('common.emailAddress')}
            maxLength={50}
            name="email"
            onChange={() => (validation.email ? setValidation(prev => ({ ...prev, email: false })) : undefined)}
            placeholder={t('common.emailAddress')}
            type="email"
          />
          <TextArea
            label={t('common.description')}
            placeholder={t('shared.updateDescriptionResponse')}
            maxLength={100}
            name="title"
            defaultValue={title ?? null}
            data-testid="editResponseUsernameGroupModal__textarea__description"
            counter={title ? title.length : '0'}
            rows="2"
          />
          <Input
            label={t('common.link')}
            placeholder={t('shared.updateLinkResponse')}
            maxLength={255}
            defaultValue={link ?? undefined}
            name="link"
            data-testid="editResponseUsernameGroupModal__input__link"
            error={
              fetcher?.data?.error && fetcher.type === 'done' && Object.keys(fetcher?.data?.error).length > 0
                ? fetcher?.data?.error
                : ''
            }
          />
        </div>
        <Modal.Actions className="mt2">
          <ButtonSubmit
            name="_action"
            value="updateResponse"
            data-testid="editResponseUsernameGroupModal__button__update"
            loading={fetcher.state !== 'idle'}
          >
            {t('common.save')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="id" value={id} />
        <input type="hidden" name="grid_id" value={grid_id} />
      </fetcher.Form>
    </Modal>
  );
};

export default EditResponseUsernameGroupModal;
