import { Button, CameraAdd24Solid } from '@flipgrid/flipkit';
import { useFetcher, useLocation } from '@remix-run/react';
import { throttle } from 'lodash-es';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import resourceRoutes from '~/constants/resourceRoutes';
import globalContext from '~/contexts/globalContext';
import recorderContext from '~/contexts/recorderContext';
import { isFlagPresent } from '~/helper/helper';
import useGetUser from '~/hooks/useGetUser';

import type { actionSizes, actionVariants } from '@flipgrid/flipkit';
import type { Topic } from 'types';
import type { reactionType } from '~/constants/videoReactionConstants';

type TokenResponse = { accessToken?: string; gridToken?: string };

type Props = {
  buttonIcon?: JSX.Element;
  buttonSize?: actionSizes;
  buttonText?: string;
  buttonTheme?: 'primary' | 'secondary' | 'transparent';
  buttonVariant?: actionVariants;
  className?: string;
  entity?: { topicResponseUrl: string; reactionType: reactionType };
  isGroupLead: boolean;
  topic: Topic;
  onClick?: () => void;
};

const RecordResponseBtn = ({
  buttonIcon,
  buttonSize = '36',
  buttonText,
  buttonTheme = 'secondary',
  buttonVariant = 'button',
  className,
  entity,
  isGroupLead,
  topic,
  onClick,
}: Props) => {
  const { loadRecorder } = useContext(recorderContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useGetUser();
  const fetcher = useFetcher<TokenResponse>();

  const { featureFlags } = useContext(globalContext);
  const isDeprecated = isFlagPresent(featureFlags, 'web-deprecation');

  const loadAuthTokens = () => {
    fetcher.load(resourceRoutes.authTokens);
  };

  const throttledOnClick = useRef(
    throttle(
      () => {
        if (onClick) onClick();
        loadAuthTokens();
      },
      3000,
      { leading: true, trailing: false },
    ),
  );

  const openRecorder = useCallback(
    ({ accessToken, gridToken }: TokenResponse) => {
      const isUsernameGroup = topic?.access_control === 'student';
      const authToken =
        user?.accountType === 'account'
          ? { Authorization: 'Bearer ' + accessToken }
          : { 'x-authorization': 'grid_token ' + gridToken };

      loadRecorder({
        authToken,
        user: user?.canvasStudentUser ?? user,
        form: {
          allowDisplayNameEdits: isUsernameGroup && !isGroupLead,
          redirectOnRequestAsync: false,
          route: pathname,
          showEmailField: user?.guest,
          type: 'topicResponse',
        },
        entity: {
          type: 'Topic',
          topicResponseUrl: entity?.topicResponseUrl,
          topic,
          reactionType: entity?.reactionType,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, entity?.reactionType, entity?.topicResponseUrl, user],
  );

  useEffect(() => {
    const hasTokens = fetcher.type === 'done' && (fetcher.data?.accessToken || fetcher.data?.gridToken);
    if (hasTokens) {
      openRecorder(fetcher.data);
    }
  }, [fetcher.data, fetcher.type, openRecorder]);

  if (buttonVariant === 'text') {
    return (
      <Button
        className={className}
        variant="text"
        size={buttonSize}
        data-testid="recordResponseBtn__button__responseCameraText"
        disabled={isDeprecated}
        aria-label={t('common.camera')}
        onClick={throttledOnClick.current}
      >
        {buttonText}
      </Button>
    );
  }

  return (
    <Button
      aria-label={t('common.camera')}
      className={className}
      data-testid="recordResponseBtn__button__responseCamera"
      disabled={isDeprecated}
      icon={buttonIcon ?? <CameraAdd24Solid />}
      size={buttonSize}
      theme={buttonTheme}
      onClick={throttledOnClick.current}
    >
      {buttonText}
    </Button>
  );
};

export default RecordResponseBtn;
