import React from 'react';

const RemixIcon = ({
  width = 20,
  height = 20,
  className = '',
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    height={height}
    width={width}
    className={className}
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      d="M2 3.25C2 2.55964 2.55964 2 3.25 2H16C16.8729 2 17.7272 2.07988 18.5558 2.23273C25.0674 3.43385 30 9.14057 30 15.9997C30 19.9171 28.3911 23.4587 25.798 25.9997H21.6829C25.1572 24.021 27.5 20.2839 27.5 15.9997C27.5 13.9243 26.9502 11.9772 25.9884 10.2963C25.9336 10.2007 25.8775 10.1059 25.8201 10.012C23.8 6.70649 20.1574 4.5 16 4.5H3.25C2.55964 4.5 2 3.94036 2 3.25ZM2 15.9997C2 18.5549 2.68454 20.9502 3.88022 23.0122C3.93405 23.1051 3.98891 23.1972 4.0448 23.2887C6.5038 27.3138 10.9379 30 16 30H27.75C28.4404 30 29 29.4404 29 28.75C29 28.0596 28.4404 27.5 27.75 27.5H16C15.2066 27.5 14.432 27.4196 13.6838 27.2666C8.44263 26.1949 4.5 21.5576 4.5 15.9997C4.5 11.7154 6.84276 7.97837 10.3171 5.99969H6.20203C3.60893 8.54072 2 12.0823 2 15.9997ZM16 10C16.6904 10 17.25 10.5596 17.25 11.25V14.75H20.75C21.4404 14.75 22 15.3096 22 16C22 16.6904 21.4404 17.25 20.75 17.25H17.25V20.75C17.25 21.4404 16.6904 22 16 22C15.3096 22 14.75 21.4404 14.75 20.75V17.25H11.25C10.5596 17.25 10 16.6904 10 16C10 15.3096 10.5596 14.75 11.25 14.75H14.75V11.25C14.75 10.5596 15.3096 10 16 10Z"
      fill="#ffffff"
    />
  </svg>
);

export default React.memo(RemixIcon);
