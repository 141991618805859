type Props = {
  activeCue: { text: string };
  closedCaptions: boolean;
};

const ClosedCaptions = ({ activeCue, closedCaptions }: Props) => {
  if (!activeCue || !closedCaptions) return null;

  return (
    <div className="closedCaptions">
      <span tabIndex={0}>{activeCue.text}</span>
    </div>
  );
};

export default ClosedCaptions;
