import { createContext } from 'react';

import type { SetStateAction } from 'react';
import type {
  FlipResponse,
  Metadata,
  Mixtape,
  MyVideosFilterValues,
  ResponseFilter,
  ResponseListFilter,
  Topic,
} from 'types';

type ResponseListContextTypes = {
  setMixtapes: (mixtapes: SetStateAction<Mixtape[]>) => void;
  setMixtapesMetadata: (metadata: SetStateAction<Metadata | undefined>) => void;
  setTopics: (topics: SetStateAction<Topic[] | undefined>) => void;
  setTopicsMetadata: (metadata: SetStateAction<Metadata | undefined>) => void;
  setVideoFilter?:
    | ((filter: SetStateAction<ResponseFilter>) => void)
    | ((filter: SetStateAction<MyVideosFilterValues>) => void);
  setVideos: (videos: SetStateAction<FlipResponse[] | undefined>) => void;
  setVideosMetadata: (metadata: SetStateAction<Metadata | undefined>) => void;
  mixtapes: Mixtape[];
  mixtapesMetadata?: Metadata;
  topics?: Topic[];
  topicsMetadata?: Metadata;
  videoFilter?: ResponseListFilter;
  videos?: FlipResponse[];
  videosMetadata?: Metadata;
};

const initialResponseListContextValues = {
  setMixtapes: () => {},
  setMixtapesMetadata: () => {},
  setTopics: () => {},
  setTopicsMetadata: () => {},
  setVideoFilter: () => {},
  setVideos: () => {},
  setVideosMetadata: () => {},
  mixtapes: [],
  mixtapesMetadata: undefined,
  topics: undefined,
  topicsMetadata: undefined,
  videoFilter: undefined,
  videos: undefined,
  videosMetadata: undefined,
};

export default createContext(initialResponseListContextValues as ResponseListContextTypes);
