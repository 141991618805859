import { createContext } from 'react';

import type { SetStateAction } from 'react';
import type { Metadata, MixtapeResponse, MixtapeResponseFilter } from 'types';

type MixtapeListContextTypes = {
  setVideos: (videos: SetStateAction<MixtapeResponse[] | undefined>) => void;
  setVideosFilter: (filter: SetStateAction<MixtapeResponseFilter>) => void;
  setVideosMetadata: (metadata: SetStateAction<Metadata | undefined>) => void;
  videos?: MixtapeResponse[];
  videosFilter?: MixtapeResponseFilter;
  videosMetadata?: Metadata;
};

const initialMixtapeListContextValues = {
  setVideos: () => {},
  setVideosFilter: () => {},
  setVideosMetadata: () => {},
  videos: undefined,
  videosFilter: undefined,
  videosMetadata: undefined,
};

export default createContext(initialMixtapeListContextValues as MixtapeListContextTypes);
